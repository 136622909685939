import React, { useEffect, useState, useContext } from "react";

// Material-UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

// Components
import CustomButton from "@Components/CustomButton";
import CustomAlert from "@Components/CustomAlert/CustomAlert";
import CustomDialog from "@Components/CustomDialog/CustomDialog";
import CustomDialogYesNo from "@Components/CustomDialogYesNo/CustomDialogYesNo";

import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Internal
import { AppContext } from "../../../AppContext";
import Utils from "sccUtils";
import Load from "sccLoad";
import { default as Permission } from "sccPermission";
import { default as EnvVar } from "sccEnvVar";
import { default as Images } from "sccImage";
import * as Language from "sccLanguage";
import { default as User } from "sccUser";
import { default as OlMap } from "sccOlMapNew";
import { default as Clock } from "sccClock";
import { default as AddressBook } from "sccAddressBook";
import { default as Poi } from "sccPoi";
import { default as Device } from "sccDevice";
import { default as Options } from "sccOptions";
import { default as Profile } from "sccProfile";
import { default as Geofence } from "sccGeofence";
import { default as Sa } from "sccSa";
import { default as Nr } from "sccNr";
import { default as Ar } from "sccAr";
import { default as UserSetting } from "sccUserSetting";
import { default as Client } from "sccClient";
import { default as Chat } from "sccChat";
import { default as Message } from "sccMessage";
import { default as AdminDevice } from "sccAdminDevice";
import { default as AdminUser } from "sccAdminUser";
import { default as Feed } from "sccFeed";
import { default as FeedMenu } from "sccFeedMenu";
import { default as Sync } from "sccSync";
import { default as Alert } from "sccAlert";
import { default as History } from "sccHistory";
import { default as IridiumBilling } from "sccIridiumBilling";
import { default as Maps } from "sccMaps";
//device
import { default as Group } from "sccGroup";
import { default as DeviceOverlay } from "sccDeviceOverlay";
import { default as Socket } from "sccSocket";
//geofence
import { default as GeofenceOverlay } from "sccGeofenceOverlay";
//geofence
import { default as PoiOverlay } from "sccPoiOverlay";
import { default as PoiCategory } from "sccPoiCategory";
import { default as ArMenu } from "sccArMenu";
import { default as NrMenu } from "sccNrMenu";
import { default as SaMenu } from "sccSaMenu";
import { default as PoiMenu } from "sccPoiMenu";
import { default as AlertMenu } from "sccAlertMenu";
import { default as GeofenceMenu } from "sccGeofenceMenu";
import { default as UserMenu } from "sccUserMenu";
import { default as DeviceMenu } from "sccDeviceMenu";
import { default as PermissionMenu } from "sccPermissionMenu";
import { default as MessageMenu } from "sccMessageMenu";
import { default as HermesGateways } from "sccHermesGateways";
import MapTopLeftLogoZones from "../../clock/components/MapTopLeftLogoZones";
import MapBottomLeft from "../../map/components/MapBottomLeftScale";
import Map from "../../map/components/Map";
import GoogleMap from "../../map/components/GoogleMap";
//to be used later
import CoordSearchbar from "../../map/components/CoordSearchbar";
import MenuWrapper from "../../menu/components/MenuWrapper";
import GeofenceSetting from "../../geofence/scripts/GeofenceSetting";
import { VideoSoftContext } from "../../video_soft/context/VideoSoftContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    zIndex: 50000,
    top: 20,
    left: "0",
    width: "102%",
    height: "100%",
    backgroundColor: theme.palette.colors.blue.dark,
    padding: theme.spacing(8),
    color: theme.palette.colors.white.main,
  },

  logo: {
    width: "200px",
  },

  soundsConfirmOverLay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    transition: "all 0.5s ease",
  },

  soundsConfirmBox: {
    position: "absolute",
    width: "auto",
    height: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    backgroundColor: theme.palette.colors.white.main,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transition: "all 0.5s ease",
  },

  displayNone: {
    display: "none",
  },

  soundsConfirmText: {
    fontSize: 18,
    fontFamily: "'proxima-nova', sans-serif",
  },

  buttonGroup: {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-around",
  },

  soundsConfirmButton: {
    marginTop: 8,
    color: theme.palette.colors.white.main,
    backgroundColor: theme.palette.colors.primary.main,
    width: 100,
  },
}));

//Module load based on the sequence followed in the angular based system
// permission, env_var, language, image, poi/category, user_setting, profile, profile_menu
// data_display, message, map, clock, alert, alert_menu, device, device_menu, group, cargo
// device_overlay, socket, poi, poi_overlay, poi_menu, geofence, geofence_overlay, geofence_menu
// nr, nr_menu, sa, sa_menu, client, ar, ar_menu, message, message_menu, user_setting_menu
// user, user_menu, permission_menu, options

function Main() {
  const classes = useStyles();

  window.Promise = require("bluebird");

  const [resolvedModuleCount, setResolvedModuleCount] = useState(0);
  const [totalModuleCount, setTotalModuleCount] = useState(0);
  const [initialized, setInitialized] = useState(Load.initialized);
  const [allModulesLoaded, setAllModulesLoaded] = useState(
    Load.allModulesLoaded
  );
  const [countdown, setCountdown] = useState(60);
  const [reloadWindow, setReloadWindow] = useState(false);

  const [mapInternetConnIssueAlert, setMapInternetConnIssueAlert] =
    useState(true);
  const [openSoundsDialog, setopenSoundsDialog] = useState(true);
  const [open, setOpen] = useState(true);
  const [refreshText] = useState(Language.default.translate("Refresh"));
  const [appState] = useContext(AppContext);
  const [mwState] = useContext(ModuleWrapperContext);
  const { getVideoSoftStatus } = useContext(VideoSoftContext);

  let $scope = mwState.$scope;
  var alwaysInit = [
    {
      module: Permission,
      dependencies: [],
    },
    {
      module: EnvVar,
      dependencies: [],
    },
    {
      module: Language.default,
      dependencies: [],
    },
    {
      module: Images,
      dependencies: [],
    },
    {
      module: UserSetting,
      dependencies: [],
    },
    {
      module: Options,
      dependencies: [],
    },
    {
      module: Profile,
      dependencies: [],
    },
    {
      module: Clock,
      dependencies: ["user_setting"],
    },
    {
      module: PoiCategory,
      dependencies: ["permission"],
    },
    {
      module: Device,
      dependencies: ["permission"],
    },
    {
      module: Socket,
      dependencies: ["device"],
    },
  ];
  var generalInit = [
    {
      module: { moduleName: "permission_menu" },
      dependencies: ["permission"],
      initFunc: function () {
        return PermissionMenu.init($scope);
      },
    },
    {
      module: User,
      dependencies: ["permission"],
    },
    {
      module: Geofence,
      dependencies: ["permission", "device"],
    },
    {
      module: HermesGateways,
      dependencies: ["permission", "device"],
    },
    {
      module: { moduleName: "user_menu" },
      dependencies: ["user"],
      initFunc: function () {
        return UserMenu.init($scope);
      },
    },
    {
      module: { moduleName: "device_menu" },
      dependencies: ["device"],
      initFunc: function () {
        return DeviceMenu.init($scope);
      },
    },
    {
      module: AddressBook,
      dependencies: ["permission"],
    },
    {
      module: Poi,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "poi_menu" },
      dependencies: ["poi"],
      initFunc: function () {
        return PoiMenu.init($scope);
      },
    },
    {
      module: Message,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "message_menu" },
      dependencies: ["message"],
      initFunc: function () {
        return MessageMenu.init($scope);
      },
    },
    {
      module: { moduleName: "geofence_menu" },
      dependencies: ["geofence"],
      initFunc: function () {
        return GeofenceMenu.init($scope);
      },
    },
    { module: GeofenceSetting, dependencies: ["geofence", "permission"] },
    {
      module: GeofenceOverlay,
      dependencies: ["map", "geofence", "geofence/geofence_setting"],
      //poi
    },
    {
      module: PoiOverlay,
      dependencies: ["image", "map"],
    },
    {
      module: Sa,
      dependencies: ["permission"],
    },
    {
      module: Chat,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "sa_menu" },
      dependencies: ["sa"],
      initFunc: function () {
        return SaMenu.init($scope);
      },
    },
    {
      module: Nr,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "nr_menu" },
      dependencies: ["nr"],
      initFunc: function () {
        return NrMenu.init($scope);
      },
    },
    {
      module: Ar,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "ar_menu" },
      dependencies: ["ar"],
      initFunc: function () {
        return ArMenu.init($scope);
      },
    },
    {
      module: Sync,
      dependencies: ["permission"],
    },
    {
      module: Alert,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "alert_menu" },
      dependencies: ["alert"],
      initFunc: function () {
        return AlertMenu.init($scope);
      },
    },
    {
      module: History,
      dependencies: ["permission"],
    },
    {
      module: OlMap,
      dependencies: ["user_setting", "env_var"],
    },
    {
      module: Group,
      dependencies: ["device", "env_var"],
    },
    {
      module: DeviceOverlay,
      dependencies: ["device", "map"],
    },
  ];

  var providerAdminInit = [
    {
      module: { moduleName: "permission_menu" },
      dependencies: ["permission"],
      initFunc: function () {
        return PermissionMenu.init($scope);
      },
    },
    {
      module: Client,
      dependencies: ["permission"],
    },
    {
      module: AdminDevice,
      dependencies: ["permission"],
    },
    {
      module: AdminUser,
      dependencies: ["permission"],
    },
    {
      module: Feed,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "feed_menu" },
      dependencies: ["feed"],
      initFunc: function () {
        return FeedMenu.init($scope);
      },
    },
    {
      module: IridiumBilling,
      dependencies: ["permission"],
    },
    {
      module: Maps,
      dependencies: ["permission"],
    },
    {
      module: AddressBook,
      dependencies: ["permission"],
    },
  ];

  const loader = (dependencies, module, initFunc) => {
    return new Promise((resolve) => {
      Load.inject(dependencies, module, initFunc);
      resolve(module);
    });
  };

  const startTime = Date.now();
  const doNextPromise = (index, initArray) => {
    loader(
      initArray[index].dependencies,
      initArray[index].module,
      initArray[index].initFunc
    ).then((module) => {
      index++;
      if (index < initArray.length) {
        doNextPromise(index, initArray);
      } else if (initArray === alwaysInit) {
        if (Utils.getCookie("userrole") === "1") {
          doNextPromise(0, providerAdminInit);
        } else {
          doNextPromise(0, generalInit);
        }
      } else {
        console.log(`@@@Total: ${(Date.now() - startTime) / 1000} seconds.`);
      }
    });
  };

  const updateLoaderStats = () => {
    const updateInterval = setInterval(() => {
      setTotalModuleCount((p) => Load.totalModuleCount);
      setResolvedModuleCount((p) => Load.resolvedModuleCount);
      if (
        Load.totalModuleCount === Load.resolvedModuleCount &&
        Load.totalModuleCount > 10
      ) {
        setAllModulesLoaded(true);

        if (!(Utils.getCookie("userrole") === "1")) {
          if (
            window.googleMapsAPIloaded ||
            window.googleMapsAPIresponseWaitExpired
          ) {
            setInitialized(true);
            clearInterval(updateInterval);
          }
        } else {
          setInitialized(true);
          clearInterval(updateInterval);
        }
      }
    }, 100);
  };

  useEffect(async () => {
    if (initialized) {
      const encryptedMsg = Utils.getCookie("videoSoft");

      if (encryptedMsg) {
        const decrypted = await Utils.decryptMsg(encryptedMsg);

        console.log("decrypted", JSON.parse(decrypted));

        const { userId, auth, serverUrl } = JSON.parse(decrypted);

        const user = Buffer.from(auth, "base64").toString();
        const name = user.split(":")[0];

        if (userId === Profile.get("id")) {
          getVideoSoftStatus(encryptedMsg, auth, name, serverUrl);
        } else {
          Utils.removeCookie("videoSoft");
        }
      }
    }
  }, [initialized]);

  useEffect(() => {
    document.title =
      Language.default.translate("TITAN BY NORTAC") +
      " " +
      Language.default.translate("Platform");
    doNextPromise(0, alwaysInit);
    Load.resolve();
    updateLoaderStats();
  }, []);

  // useEffect(() => {
  //Leaving the countdown code behind incase we decide to use it later
  // let reloadTimeout;
  // if (reloadWindow) {
  //   reloadTimeout = setInterval(function () {
  //     let newCnt;
  //     setCountdown((p) => {
  //       newCnt = p - 1;
  //       return p - 1;
  //     });
  //     if (parseInt(newCnt) === 0) {
  //       setReloadWindow(false);
  //       document.location.reload(true);
  //     }
  //   }, 1000);
  // }
  // return () => {
  //   window.clearInterval(reloadTimeout);
  // };
  // }, [reloadWindow]);

  const handleReloadOkAction = () => {
    setReloadWindow(false);
    document.location.reload(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function displaySnackbarMessage(e) {
    const title = e.target.getAttribute("data-title");
    const message = e.target.getAttribute("data-message");
    const subMessage = e.target.getAttribute("data-submessage");
    const variant = e.target.getAttribute("data-variant");
    const acknowledgeAlertId = e.target.getAttribute("data-acknowledgealertid");
    appState.displaySnackbarMessage({
      title: title,
      message: message,
      subMessage: subMessage !== "null" ? subMessage : null,
      variant: variant,
      acknowledgeAlertId: acknowledgeAlertId,
    });
  }

  function poiDisplaySnackMessage(e) {
    const title = e.target.getAttribute("data-title");
    const message = e.target.getAttribute("data-message");
    const subMessage = e.target.getAttribute("data-submessage");
    const variant = e.target.getAttribute("data-variant");
    const poi = e.target.getAttribute("data-poi");
    appState.poiMenuDisplaySnackbarMessage({
      title: title,
      message: message,
      subMessage: subMessage !== "null" ? subMessage : null,
      variant: variant,
      poi: poi,
    });
  }

  function geofenceDisplaySnackMessage(e) {
    const title = e.target.getAttribute("data-title");
    const message = e.target.getAttribute("data-message");
    const subMessage = e.target.getAttribute("data-submessage");
    const variant = e.target.getAttribute("data-variant");
    const geofence = e.target.getAttribute("data-geofence");
    appState.geofenceMenuDisplaySnackbarMessage({
      title: title,
      message: message,
      subMessage: subMessage !== "null" ? subMessage : null,
      variant: variant,
      geofence: geofence,
    });
  }

  const handleSoundClick = (isAllowPanicAudio) => {
    const data = {
      id: 1,
      panic_audio: isAllowPanicAudio,
    };

    const message = {
      title: Language.default.translate("Settings"),
      text: Language.default.translate("Successfully Updated"),
      noRefresh: true,
    };

    mwState.updateModuleData(data, "/api/v1/user_setting", message);
  };

  return (
    <div>
      {!initialized ? (
        <Modal open={open} onClose={handleClose}>
          <Grid
            container
            spacing={5}
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className={classes.paper}
          >
            <Grid item>
              <CircularProgress color="inherit" size="15rem" thickness={5} />
            </Grid>
            <Grid item>
              <img
                className={classes.logo}
                src={
                  Images.getImageCollection("platform_images").scc_titan_logo
                    .default
                }
                alt="TITAN BY NORTAC"
              />
            </Grid>
            {!allModulesLoaded ? (
              <Grid item>
                <p>
                  {Language.default.translate("Loading")}&nbsp;
                  {resolvedModuleCount}&nbsp;
                  {Language.default.translate("of")}&nbsp;
                  {totalModuleCount}&nbsp;
                  {Language.default.translate("Modules")}
                </p>
              </Grid>
            ) : null}
            {allModulesLoaded && !initialized ? (
              <Grid item>
                <p>
                  {Language.default.translate("Initializing")}&nbsp;
                  {Language.default.translate("Maps")}
                </p>
              </Grid>
            ) : null}
            <Grid container item justifyContent="flex-end">
              <CustomButton
                size="large"
                color="cancel"
                id="btnRefresh"
                variant="contained"
                type="submit"
              >
                {refreshText}
              </CustomButton>
            </Grid>
          </Grid>
        </Modal>
      ) : null}

      {initialized && !Permission.verify("client", "view") && (
        <>
          <CustomDialog
            type="refresh"
            open={reloadWindow}
            onOkAction={handleReloadOkAction}
            text={Language.default.translate(
              "The account administrator has changed your permissions. A screen reload is required to apply the necessary changes."
            )}
          />
          <CustomDialogYesNo
            text={
              Language.default.translate(
                "Do you want to allow sounds on this website"
              ) +
              "? " +
              Language.default.translate(
                "You can change this in the settings later"
              )
            }
            open={openSoundsDialog}
            onNoAction={() => {
              setopenSoundsDialog(false);
              handleSoundClick(false);
            }}
            onYesAction={() => {
              setopenSoundsDialog(false);
              handleSoundClick(true);
            }}
          />
          {!window.googleMapsAPIloaded ? (
            <CustomAlert
              titleText={Language.default.translate("Unable to Connect")}
              text={
                Language.default.translate(
                  "Map layer(s) may fail to display."
                ) +
                " " +
                Language.default.translate(
                  "Please check your internet connection or Firewall settings."
                )
              }
              open={mapInternetConnIssueAlert}
              onOkAction={() => setMapInternetConnIssueAlert(false)}
            />
          ) : null}

          <MapBottomLeft />
        </>
      )}
      <MapTopLeftLogoZones />
      {/* todo: unshow searchBar right now, complete logic to display corrdSearchBar in the future */}
      {Utils.getCookie("userrole") !== "1" ? <CoordSearchbar /> : null}
      <Map />
      <GoogleMap />
      <MenuWrapper />
      <button
        style={{ display: "none" }}
        onClick={() => {
          // setCountdown(60);
          setReloadWindow(true);
        }}
        id="reloadHelper"
      >
        Hidden Button
      </button>
      <button
        style={{ display: "none" }}
        onClick={displaySnackbarMessage}
        data-title=""
        data-message=""
        data-submessage=""
        data-variant=""
        data-acknowledgealertid=""
        id="snackbarHelper"
      >
        Hidden Button
      </button>

      <button
        style={{ display: "none" }}
        onClick={poiDisplaySnackMessage}
        data-title=""
        data-message=""
        data-submessage=""
        data-variant=""
        data-poi=""
        id="poiMenuSnackbarHelper"
      >
        Hidden Button
      </button>

      <button
        style={{ display: "none" }}
        onClick={geofenceDisplaySnackMessage}
        data-title=""
        data-message=""
        data-submessage=""
        data-variant=""
        data-geofence=""
        id="geofenceMenuSnackbarHelper"
      >
        Hidden Button
      </button>
    </div>
  );
}

export default Main;

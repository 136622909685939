import React, { Component } from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MapTools from "../MapTools";
import MapSettings from "../MapSettings";
import MapLayers from "../MapLayers";
import QuickVSButton from "../QuickVideoSoftButton";
import "./MapUtilities.scss";

const OlMap = require("sccOlMapNew").default;
const UserSetting = require("sccUserSetting").default;

export default class MapUtilities extends Component {
  render() {
    const mapUtilitiesButtons = OlMap.getOptions("layerSwitcherDisplay") ? (
      <ButtonGroup
        aria-label="outlined vertical contained primary button group"
        variant="contained"
      >
        <QuickVSButton history={this.props.history || 0} />
        <MapTools history={this.props.history || 0} />
        <MapSettings settings={UserSetting.get()} />
        <MapLayers history={this.props.history || 0} />
      </ButtonGroup>
    ) : (
      ""
    );

    return <div className="mapUtilitiesButtons">{mapUtilitiesButtons}</div>;
  }
}

import React from "react";
import ReactDOM from "react-dom";

// Material-UI
import { ThemeProvider } from "@material-ui/core/styles";

// Internal
import { AppContextProvider } from "./AppContext";
import { CustomAssetSelectorContextProvider } from "./reusable-components/CustomAssetWrapper/CustomAssetSelectorContext";
import { ModuleWrapperContextProvider } from "./reusable-components/ModuleWrapper/ModuleWrapperContext";
import UserSettingContextProvider from "./modules/user_setting/context/UserSettingContext";
import SelectedGeofenceContextProvider from "./modules/geofence/components/context/SelectedGeofenceContext";
import PoiCategoryContextProvider from "./modules/poi/context/PoiCategoryContext";
import GeofenceSettingContextProvider from "./modules/geofence/context/GeofenceSettingContext";
import VideoSoftContextProvider from "./modules/video_soft/context/VideoSoftContext";
import App from "./App";
import theme from "./styles/theme";
import CustomSnackbar from "@Components/CustomSnackbar";
import "./styles/global.scss";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CustomSnackbar>
      <AppContextProvider>
        <ModuleWrapperContextProvider>
          <UserSettingContextProvider>
            <SelectedGeofenceContextProvider>
              <CustomAssetSelectorContextProvider>
                <PoiCategoryContextProvider>
                  <GeofenceSettingContextProvider>
                    <VideoSoftContextProvider>
                      <App />
                    </VideoSoftContextProvider>
                  </GeofenceSettingContextProvider>
                </PoiCategoryContextProvider>
              </CustomAssetSelectorContextProvider>
            </SelectedGeofenceContextProvider>
          </UserSettingContextProvider>
        </ModuleWrapperContextProvider>
      </AppContextProvider>
    </CustomSnackbar>
  </ThemeProvider>,
  document.getElementById("root")
);

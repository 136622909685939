import React, { useContext } from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MinimizeIcon from "@material-ui/icons/Minimize";
import MaximizeIcon from "@material-ui/icons/Maximize";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Button from "@material-ui/core/Button";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import HistoryOverlay from "sccHistoryOverlay";
import HistoryTrailOverlay from "sccHistoryTrailOverlay";
import Images from "sccImage";
import Language from "sccLanguage";
import { MinimizeContext } from "../../context/MinimizeContext";

const useStyles = makeStyles((theme) => ({
  DataDisplayHeader: {
    background: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: theme.palette.colors.blue.dark,
    display: "flex",
  },

  DataDisplayHeaderToolbar: {
    minHeight: 30,
    padding: "1px 4px",
  },

  DataDisplayHeaderAvatar: {
    width: 30,
    height: 30,
    borderRadius: "inherit",
    backgroundColor: "transparent",
    justifyContent: "flex-start",
  },

  root: {
    flexGrow: 1,
    "& .dataDisplayLockButton": {
      padding: "5px 10px",
      borderRadius: "0",
      width: "30px",
      minWidth: "30px",
      height: "30px",
      margin: "0 !important",
      border: `1px solid ${theme.palette.colors.gray.main} !important`,
      backgroundColor: theme.palette.colors.gray.pure,
      color: alpha(theme.palette.colors.black.main, 0.25),

      "&:hover": {
        backgroundColor: theme.palette.colors.gray.dark,
        color: theme.palette.colors.blue.dark,
      },

      "&.locked": {
        background: "yellow",
        color: theme.palette.colors.black.main,
      },

      "& span span": {
        display: "flex",
      },
    },
  },

  flex1: {
    flex: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    flexGrow: 1,
    fontSize: 18,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    paddingRight: 4,
    fontSize: 18,
    maxWidth: 155,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  titleIcon: {
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: "4px",
  },

  lockSize: {
    fontSize: 13,
  },

  DataDisplayHeaderButton: {
    minWidth: 30,
    width: 30,
    height: 30,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
    },

    "&.locked": {
      background: "yellow",
    },
  },

  DataDisplayHeaderIcon: {
    color: alpha(theme.palette.colors.black.main, 0.25),
    fontSize: 18,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: theme.palette.colors.blue.dark,
    },
  },

  DataDisplayHeaderImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },

  DataDisplayHeaderMiniComImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: 20,
    paddingLeft: 4,
  },
}));

export default function DataDisplayHeader({
  device,
  history,
  isDeviceLocked,
  updateDeviceIsLocked,
  isHistoryTrail = false,
}) {
  var { state, toggleMinimize } = useContext(MinimizeContext);

  const classes = useStyles();

  const obj = device.id ? device : Object.values(device)[0];

  const handleClose = () => {
    if (isHistoryTrail) {
      HistoryTrailOverlay.deselectFeature();
    } else if (history === "1") {
      HistoryOverlay.deselectFeature();
    } else {
      DeviceOverlay.deselectFeature();
    }
    toggleMinimize = true;
  };

  const handleLock = () => {
    updateDeviceIsLocked(!isDeviceLocked);
    DeviceOverlay.toggleFeatureLock();
  };

  const lockIcon = isDeviceLocked ? <LockIcon /> : <LockOpenIcon />;

  const returnImage = (obj) => {
    if (obj.type === "MINI-SATCOM") {
      return (
        <img
          className={classes.DataDisplayHeaderMiniComImage}
          alt={"MINI-SATCOM"}
          src={Images.getImageCollection("device_images").mini_satcom.default}
        />
      );
    } else {
      return (
        <img
          className={classes.DataDisplayHeaderImage}
          alt={Images.get(Device.getDeviceType(obj.type_id).image_id).name}
          src={Images.getImageSource(
            Device.getDeviceType(obj.type_id).image_id
          )}
        />
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className="olMapPopupArrow" />
      <AppBar position="static" className={classes.DataDisplayHeader}>
        <Toolbar className={classes.DataDisplayHeaderToolbar}>
          <Avatar className={classes.DataDisplayHeaderAvatar}>
            {returnImage(obj)}
          </Avatar>

          <span className={classes.flex1}>
            <CustomTooltip title={obj.name} arrow placement="top">
              <Typography variant="h6" className={classes.title}>
                <span className={classes.titleText}>{obj.name}</span>
              </Typography>
            </CustomTooltip>
          </span>

          <Button
            onClick={handleLock}
            className={`dataDisplayLockButton
          ${isDeviceLocked ? "locked" : null}`}
          >
            <CustomTooltip
              title={
                !isDeviceLocked
                  ? Language.translate("Lock and Centre")
                  : Language.translate("Unlock")
              }
              arrow
              placement="top"
            >
              {lockIcon}
            </CustomTooltip>
          </Button>

          <CustomTooltip
            title={
              state.minimize
                ? Language.translate("Maximize")
                : Language.translate("Minimize")
            }
            arrow
            placement="top"
          >
            <IconButton
              size="medium"
              color="inherit"
              onClick={toggleMinimize}
              className={classes.DataDisplayHeaderButton}
            >
              {state.minimize ? (
                <MaximizeIcon className={classes.DataDisplayHeaderIcon} />
              ) : (
                <MinimizeIcon className={classes.DataDisplayHeaderIcon} />
              )}
            </IconButton>
          </CustomTooltip>
          <CustomTooltip
            title={Language.translate("Close")}
            arrow
            placement="top"
          >
            <IconButton
              size="medium"
              color="inherit"
              onClick={handleClose}
              className={classes.DataDisplayHeaderButton}
            >
              <CloseIcon className={classes.DataDisplayHeaderIcon} />
            </IconButton>
          </CustomTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}

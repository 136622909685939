import React, { useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { Grid, makeStyles } from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

// Internal
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";
import VideoSoftDataDisplay from "@videoSoft/components/DataDisplay/videoSoftDataDisplay";
import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";

const useStyles = makeStyles((theme) => ({
  videoSoftIcon: {
    marginRight: 4,
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  VideoSoftBody: {
    maxWidth: 750,
    marginTop: -12,

    "& ul": {
      maxHeight: 250,
      overflowY: "auto",
    },
  },

  clusterContainer: {
    padding: "0px 8px",
    cursor: "pointer",
    minHeight: "37px",
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.main,
    },
  },
}));

export default function ClusterList({ gatewayIds }) {
  const { videoSofts } = useContext(VideoSoftContext);

  function handleClick(id) {
    VideoSoftOverlay.deselectFeature();
    VideoSoftOverlay.hideClusterPopup();
    VideoSoftOverlay.selectFeatureById(id, false);
    //  update VideoSoft popup data
    VideoSoftDataDisplay.updateFeatureSelected(id);
    return true;
  }

  const classes = useStyles();

  return (
    <div className={classes.VideoSoftBody}>
      <ul className="clusterVideoSoftListing">
        {!_.isEmpty(gatewayIds)
          ? gatewayIds.map((gatewayId, index) => {
              const vs = videoSofts.find((vs) => vs.id === gatewayId);
              return (
                <React.Fragment key={gatewayId}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.clusterContainer}
                    key={index}
                    onClick={() => handleClick(gatewayId)}
                  >
                    <Grid item className={classes.videoSoftIcon}>
                      <PlayCircleFilledIcon style={{ fontSize: "17px" }} />
                    </Grid>
                    <Grid item>{vs?.title || "NO TITLE"}</Grid>
                  </Grid>
                </React.Fragment>
              );
            })
          : null}
      </ul>
    </div>
  );
}

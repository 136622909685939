import React, { useContext } from "react";

// Internal
import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";
import VideoSoft from "@videoSoft/scripts/VideoSoft";
import Utils from "sccUtils";

// Material-UI
import { Grid, makeStyles, Box } from "@material-ui/core";

//style
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  tableContainer: {
    display: "flex",
    width: "700px",
    height: "30px",
    backgroundColor: "#f5f5f5",
    flexWrap: "wrap",
  },
  bodyContainer: {
    flexDiraction: "row ",
    display: "flex",
    width: "720px",
    height: "30px",
    backgroundColor: "#f5f5f5",
    flexWrap: "wrap",
  },

  tableCell: {
    display: "grid",
    flex: 1,
    padding: "5px",
    border: "1px solid #ddd",
    backgroundColor: "#f4f4f4",
    fontSize: "0.875rem",
  },
  streamCellTrue: {
    display: "grid",
    flex: 1,
    padding: "5px",
    border: "1px solid #ddd",
    backgroundColor: "#f4f4f4",
    fontSize: "0.875rem",
    backgroundColor: theme.palette.colors.green.main,
  },

  streamCellFalse: {
    display: "grid",
    flex: 1,
    padding: "5px",
    border: "1px solid #ddd",
    backgroundColor: "#f4f4f4",
    fontSize: "0.875rem",
    backgroundColor: theme.palette.colors.red.main,
  },

  headerCell: {
    flex: 1,
    border: "1px solid #ddd",
    padding: "5px",
    backgroundColor: "hsl(0, 25.00%, 95.30%)",
    fontSize: "0.875rem",
    fontWeight: "700",
  },
  urlText: {
    color: "#334f9b",
    textDecoration: "underline",
  },
}));

export default function VideoSoftInfo({ gateway }) {
  const classes = useStyles();

  const { videoSoftAuth, videoSoftServer } = useContext(VideoSoftContext);

  const createData = [
    "Active",
    "Stream Name",
    "Stream ID",
    "Machine ID",
    "URLs",
  ];

  const openPopupWindows = async (machineId, streamId, server, auth, title) => {
    const toPass = JSON.stringify({
      machineId,
      streamId,
      server,
      auth,
      title,
    });

    const encryption = await Utils.encryptMsg(toPass);

    VideoSoft.openWindow(`/${encryption}`);
  };

  const returnStreamData = () => {
    let returnComponents = [];

    if (gateway.streams?.length > 0) {
      gateway.streams.forEach((item, index) => {
        const component = (
          <Grid item key={index} container>
            <Grid
              item
              xs={1}
              className={
                item.streaming
                  ? classes.streamCellTrue
                  : classes.streamCellFalse
              }
            >
              {item.streaming ? "True" : "False"}
            </Grid>

            <Grid item className={classes.tableCell}>
              {item.title}
            </Grid>

            <Grid item xs={2} className={classes.tableCell}>
              {item.id}
            </Grid>

            <Grid item className={classes.tableCell}>
              {gateway.machineid}
            </Grid>

            <Grid
              item
              className={`${classes.tableCell} ${classes.urlText} ${{
                cursor: "pointer",
              }}`}
              onClick={(e) => {
                e.preventDefault();
                openPopupWindows(
                  gateway.machineid,
                  item.id,
                  encodeURIComponent(videoSoftServer),
                  videoSoftAuth,
                  gateway.title
                );
              }}
              onMouseOver={(e) => e.currentTarget}
            >
              stream popup
            </Grid>
          </Grid>
        );

        returnComponents.push(component);
      });
    }

    return returnComponents;
  };
  return (
    <Box className={classes.tableContainer}>
      {/* Header */}
      <Grid item xs={1} className={classes.headerCell}>
        {createData[0]}
      </Grid>

      <Grid className={classes.headerCell}>{createData[1]}</Grid>

      <Grid item xs={2} className={classes.headerCell}>
        {createData[2]}
      </Grid>

      <Grid className={classes.headerCell}>{createData[3]}</Grid>

      <Grid className={classes.headerCell}>{createData[4]}</Grid>

      {/* Body  */}
      <Box className={classes.bodyContainer}>
        {gateway && gateway.streams.length > 0 && returnStreamData()}
      </Box>
    </Box>
  );
}

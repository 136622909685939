import React, { useState, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Internal
import { moduleListing } from "@Styles/ModuleListing";
import VideoSoftLogIn from "@videoSoft/body/VideoSoftLogIn";
import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";
import VideoSoftList from "@videoSoft/body/VideoSoftList";
import VideoSoftForm from "@videoSoft/body/VideoSoftForm";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

export default function ModuleWrapperVideoSoftBody() {
  const { isVideoSoftLoggedIn, displayVidoeSoftForm, videoSofts } =
    useContext(VideoSoftContext);

  if (!isVideoSoftLoggedIn) 
   return <VideoSoftLogIn />
  

  return (
    displayVidoeSoftForm ? 
      <VideoSoftForm gateWay={videoSofts} />
     : 
      <VideoSoftList />
  )
}

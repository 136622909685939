// External
import _ from "lodash";
import olStyleStyle from "ol/style/Style";
import olStyleText from "ol/style/Text";
import olStyleFill from "ol/style/Fill";
import olStyleStroke from "ol/style/Stroke";
import olStyleRegularShape from "ol/style/RegularShape";

// Internal
import { default as OlMapOverlay } from "sccOlMapOverlay";
import TimeUtils from "sccTimeUtils";
import UserSetting from "sccUserSetting";
import Device from "sccDevice";
import Alert from "sccAlert";

// the diameter in pixels to use to size the icon
const iconDiameter = 40;
const clusterDiameter = 40;

class DeviceOverlay extends OlMapOverlay.OlMapOverlay {
  constructor(options) {
    super(options);
    this.moduleName = "device_overlay";
  }

  init() {
    this.addOverlay();
    this.addFeature({
      data: getReportedDevices(),
    });
    super.init();
  }

  /**
   * @override
   */
  getFeatureData() {
    return getReportedDevices();
  }

  /**
   * gets the style for showing a feature
   * @param {Object} feature feature that is being styled
   * @return {Object} style to show the feature
   * @override
   */
  getStyle(feature) {
    super.getStyle();
    const features = feature.get("features");
    var size = (features && features.length) || 1;

    if (size === 1) {
      const f = features ? features[0] : feature;
      return getSingularStyle(f);
    }

    return getClusterStyle(features);
  }

  /**
   * opens the history module with the device selected
   * @param {Object} device device information object
   */
  openAssetInHistory(device) {
    const Historic = require("sccHistory").default;
    Historic.openWindowLoadDevice(device.id);
  }

  getSelectStyle(feature) {
    // if feature has id, then it is the selected feature and must be
    // styled as before.
    // if feature does not have id, then it is the select icon
    if (feature.getId()) {
      return getSingularStyle(feature);
    }

    const fill = new olStyleFill({ color: [0, 0, 128, 0.2] });
    const stroke = new olStyleStroke({
      color: "black",
      lineDash: [7, 7],
      width: 2,
    });
    const iconStyle = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: fill,
        stroke: stroke,
        points: 4,
        radius: getSelectIconRadius(),
        angle: Math.PI / 4,
      }),
    });
    return iconStyle;
  }
}

/**
 * gets the radius of selection icon
 */
function getSelectIconRadius() {
  // get the sqrt(2) times the diameter/2 since it is a square
  // adds 2 pixels to avoid intersecting the icon
  return (iconDiameter * Math.SQRT2) / 2 + 2;
}

/**
 * gets the list of devices that has reported with respect to user's
 * report age setting
 *
 * @return {Array} list of devices
 */
function getReportedDevices() {
  const UserSetting = require("sccUserSetting").default;
  const Device = require("sccDevice").default;
  let reportAge = UserSetting.get("report_age");

  let devices = Device.get();

  // if report age is 'None' show all devices
  if (reportAge !== -1) {
    // getting report age in seconds
    reportAge = reportAge * 60 * 60;

    devices = _.omitBy(Device.get(), function (device) {
      var now = TimeUtils.getTimestamp();
      return now - device.report_timestamp > reportAge;
    });
  }

  devices = _.omitBy(devices, (device) => {
    return device.longitude == null || device.latitude == null;
  });

  return _.values(devices);
}

const singularStyleCache = {};

function getSingularStyle(feature) {
  const device = Device.get(feature.getId());
  const strokeWidth = 4;
  const olStyleCircle = require("ol/style/Circle").default;
  const alertColor = getTextOutlineColor(device.id);
  const deviceColor = device.color;
  const text = getIconText(device);
  const tag = getSingularStyleCacheTag(
    device,
    alertColor,
    text,
    deviceColor,
    device.mode
  );

  if (singularStyleCache[tag]) return singularStyleCache[tag];

  if (device.type === "MINI-SATCOM") {
    const iconStyle = new olStyleStyle({
      // image: new olStyleIcon(iconOptions),
      image: new olStyleCircle({
        radius: iconDiameter / 2 - strokeWidth,
        stroke: new olStyleStroke({
          color: deviceColor,
          width: strokeWidth,
        }),
        fill: new olStyleFill({
          color: [255, 255, 255, 1],
        }),
      }),
      text: new olStyleText({
        font: "14px Courier New,Calibri,sans-serif",
        fill: new olStyleFill({ color: "#fff" }),
        stroke: new olStyleStroke({
          color: alertColor,
          width: 5,
        }),
        textAlign: "center",
        offsetX: iconDiameter / 2,
        offsetY: iconDiameter / 2,
        text: text,
      }),
    });

    const style2 = new olStyleStyle({
      text: new olStyleText({
        font: "900 18px FontAwesome",
        text: "\uf7c0",
        // stroke: new olStyleStroke({
        // 	color: color, width: 3
        // }),
        fill: new olStyleFill({
          color: alertColor,
        }),
      }),
    });

    singularStyleCache[tag] = [iconStyle, style2];
  } else if (Device.getDeviceMode(device) != "Gateway Device") {
    const iconStyle = new olStyleStyle({
      // image: new olStyleIcon(iconOptions),
      image: new olStyleCircle({
        radius: iconDiameter / 2 - strokeWidth,
        stroke: new olStyleStroke({
          color: deviceColor,
          width: strokeWidth,
        }),
        fill: new olStyleFill({
          color: [255, 255, 255, 1],
        }),
      }),
      text: new olStyleText({
        font: "14px Courier New,Calibri,sans-serif",
        fill: new olStyleFill({ color: "#fff" }),
        stroke: new olStyleStroke({
          color: alertColor,
          width: 5,
        }),
        textAlign: "center",
        offsetX: iconDiameter / 2,
        offsetY: iconDiameter / 2,
        text: text,
      }),
    });

    const style2 = new olStyleStyle({
      text: new olStyleText({
        font: getHeadingFont(device.speed),
        text: getHeadingText(device.speed),
        // stroke: new olStyleStroke({
        // 	color: color, width: 3
        // }),
        fill: new olStyleFill({
          color: alertColor,
        }),
        rotation: getHeadingRotation(device.heading, device.speed),
        rotateWithView: getIconRotateWithView(),
      }),
    });

    singularStyleCache[tag] = [iconStyle, style2];
  } else {
    const outerCircleWidth = 2;
    const innerCircleRadius = 8;
    const innerCircleWidth = 3;
    const squareDisplacementValue = 12;

    const outerCircle = new olStyleStyle({
      image: new olStyleCircle({
        radius: iconDiameter / 2 - outerCircleWidth,
        stroke: new olStyleStroke({
          color: deviceColor,
          width: outerCircleWidth,
        }),
        fill: new olStyleFill({
          color: [255, 255, 255, 1],
        }),
      }),
      text: new olStyleText({
        font: "16px Courier New,Calibri,sans-serif",
        fill: new olStyleFill({ color: "#fff" }),
        stroke: new olStyleStroke({
          color: alertColor,
          width: 5,
        }),
        textAlign: "left",
        offsetX: iconDiameter / 2,
        offsetY: iconDiameter / 2,
        text: text,
      }),
    });

    const innerCircle = new olStyleStyle({
      image: new olStyleCircle({
        radius: iconDiameter / 2 - innerCircleRadius,
        stroke: new olStyleStroke({
          color: alertColor,
          width: innerCircleWidth / 2,
        }),
        fill: new olStyleFill({
          color: [255, 255, 255, 1],
        }),
      }),
    });

    const topSquare = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: new olStyleFill({
          color: alertColor,
        }),
        stroke: new olStyleStroke({
          color: alertColor,
        }),
        points: 4,
        radius: 4,
        angle: Math.PI / 4,
        displacement: [0, squareDisplacementValue],
      }),
    });

    const rightSquare = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: new olStyleFill({
          color: alertColor,
        }),
        stroke: new olStyleStroke({
          color: alertColor,
        }),
        points: 4,
        radius: 4,
        angle: Math.PI / 4,
        displacement: [squareDisplacementValue, 0],
      }),
    });

    const leftSquare = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: new olStyleFill({
          color: alertColor,
        }),
        stroke: new olStyleStroke({
          color: alertColor,
        }),
        points: 4,
        radius: 4,
        angle: Math.PI / 4,
        displacement: [squareDisplacementValue * -1, 0],
      }),
    });

    const bottomSquare = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: new olStyleFill({
          color: alertColor,
        }),
        stroke: new olStyleStroke({
          color: alertColor,
        }),
        points: 4,
        radius: 4,
        angle: Math.PI / 4,
        displacement: [0, squareDisplacementValue * -1],
      }),
    });

    if (device.speed > 0) {
      const arrow = new olStyleStyle({
        text: new olStyleText({
          font: "normal 18px FontAwesome",
          text: getHeadingText(device.speed),
          fill: new olStyleFill({
            color: alertColor,
          }),
          rotation: getHeadingRotation(device.heading, device.speed),
          rotateWithView: getIconRotateWithView(),
          displacement: [1000, 1000],
        }),
      });

      singularStyleCache[tag] = [
        outerCircle,
        innerCircle,
        arrow,
        topSquare,
        rightSquare,
        leftSquare,
        bottomSquare,
      ];
    } else {
      const centerSquare = new olStyleStyle({
        image: new olStyleRegularShape({
          fill: new olStyleFill({
            color: alertColor,
          }),
          stroke: new olStyleStroke({
            color: alertColor,
          }),
          points: 4,
          radius: 9,
          angle: Math.PI / 4,
        }),
      });

      const cross = new olStyleStyle({
        image: new olStyleRegularShape({
          fill: new olStyleFill({
            color: alertColor,
          }),
          stroke: new olStyleStroke({
            color: alertColor,
          }),
          points: 4,
          radius: 12,
          radius2: 0,
          angle: 0,
        }),
      });

      singularStyleCache[tag] = [
        outerCircle,
        innerCircle,
        cross,
        centerSquare,
        topSquare,
        rightSquare,
        leftSquare,
        bottomSquare,
      ];
    }
  }

  return singularStyleCache[tag];
}

function getSingularStyleCacheTag(
  device,
  alertColor,
  text,
  deviceColor,
  deviceMode
) {
  return (
    device.speed +
    "_" +
    device.heading +
    "_" +
    alertColor +
    "_" +
    text +
    "_" +
    deviceColor +
    "_" +
    device.type_id +
    "_" +
    deviceMode
  );
}

function getHeadingFont(speed) {
  if (speed > 3) {
    return "900 24px FontAwesome";
  } else {
    return "900 18px FontAwesome";
  }
}
DeviceOverlay.prototype.getHeadingFontExternal = function (speed) {
  return getHeadingFont(speed);
};

function getHeadingText(speed) {
  if (speed > 3) {
    return "\uf062";
  } else {
    return "\uf04d";
  }
}

DeviceOverlay.prototype.getHeadingTextExternal = function (speed) {
  return getHeadingText(speed);
};

function getHeadingRotation(heading, speed) {
  if (speed <= 3) return 0;

  return (heading * Math.PI) / 180;
}

DeviceOverlay.prototype.getHeadingRotationExternal = function (heading, speed) {
  return getHeadingRotation(heading, speed);
};

/**
 * get whether or not the icon needs to rotate when map rotates
 * @return {Boolean} true if icon needs to rotate and false otherwise
 */
function getIconRotateWithView() {
  return true;
}

/**
 * gets the icon image to be shown when features are clustered
 * @param {Array} features list of features in the cluster
 * @return {String} string representation of the image
 */
function getClusterColor(features) {
  let imageTag;
  let currentPriority = 1000;
  _.each(features, function (feature) {
    const deviceId = feature.getId();
    const newTag = getAlertTag(deviceId);
    const newAlertPriority = Alert.getAlertPriority(newTag);
    if (!imageTag || currentPriority > newAlertPriority) {
      currentPriority = newAlertPriority;
      imageTag = newTag;
    }
  });
  const color = require("ol/color");
  return color.asArray(Alert.getAlertColor(imageTag));
}

const clusterStyleCache = {};

/**
 * returns the style for showing clustered features
 * @param {Array} features list of features in the cluster
 * @return {Object} cluster style
 */
function getClusterStyle(features) {
  var size = features.length;
  const olStyleCircle = require("ol/style/Circle").default;
  const strokeWidth = 2;
  const color = getClusterColor(features);
  const tag = size + "_" + color;
  if (clusterStyleCache[tag]) return [clusterStyleCache[tag]];

  clusterStyleCache[tag] = new olStyleStyle({
    image: new olStyleCircle({
      radius: clusterDiameter / 2 - strokeWidth,
      stroke: new olStyleStroke({
        color: "white",
        width: strokeWidth,
      }),
      fill: new olStyleFill({
        color: color,
      }),
    }),
    text: new olStyleText({
      font: "15px Calibri,sans-serif",
      text: size.toString(),
      fill: new olStyleFill({
        color: "#fff",
      }),
    }),
  });

  return [clusterStyleCache[tag]];
}

/**
 * gets the emergency tag to be used to fetch respective image from the collection
 * @param {Number} deviceId device id
 * @return {String} image tag for collection
 */
function getAlertTag(deviceId) {
  let imageTag = "Normal";
  if (Alert.getDeviceAlert(deviceId, "Emergency")) {
    imageTag = "Emergency";
  } else if (Alert.getDeviceAlert(deviceId, "Geofence")) {
    imageTag = "Geofence";
  } else if (Alert.getDeviceAlert(deviceId, "Speed")) {
    imageTag = "Speed";
  } else if (Alert.getDeviceAlert(deviceId, "Cargo")) {
    imageTag = "Cargo";
  } else if (Alert.getDeviceAlert(deviceId, "Non-Report")) {
    imageTag = "Non-Report";
  }

  return imageTag;
}

/**
 * gets the alert color to be used for text outline
 * @param {Number} deviceId device id
 * @return {String} hex representation of the alert color
 */
function getTextOutlineColor(deviceId) {
  return Alert.getDeviceAlertColor(deviceId);
}

/**
 * gets the text to be shown on the device icon
 * @param {Object} device device object
 * @return {String} text at device position
 */
function getIconText(device) {
  let deviceName = "";
  if (UserSetting.get("asset_label")) {
    deviceName = device.name;
  }

  let annotation = "";
  let separatorLine = "";
  if (UserSetting.get("asset_annotation")) {
    annotation = device.annotation;
    separatorLine = annotation !== "" && deviceName !== "" ? "\n" : "";
  }

  return deviceName + separatorLine + annotation;
}

export default new DeviceOverlay({
  id: "device_overlay",

  // overlay title
  title: "Assets",

  zIndex: 160,

  // whether or not features of the layer are selectable
  isSelectable: true,

  /**
   * Contains clustering options and if provided layer would be clustered.
   */
  cluster: {
    popupContainerId: "olMapDeviceClusterPopup",
    offset: [iconDiameter / 2 + 5, -(35 + iconDiameter / 2)],
  },

  /**
   * Provides info for the popup window.
   * Popup would be disabled if this property is not provided
   */
  popup: {
    containerId: "olMapDevicePopup",
    offset: [iconDiameter / 2 + 5, -(35 + iconDiameter / 2)],
  },
});

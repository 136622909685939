import React, { createContext, useState, useEffect } from "react";

import { io } from "socket.io-client";

// Internal
import Utils from "sccUtils";
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";

export const VideoSoftContext = createContext();

export default function VideoSoftContextProvider(props) {
  const [videoSofts, setVideoSofts] = useState(null);
  const [videoSoftAuth, setVideoSoftAuth] = useState(null);
  const [isVideoSoftLoggedIn, setIsVideoSoftLoggedIn] = useState(false);
  const [videoSoftServer, setVideoSoftServer] = useState("");
  const [displayVidoeSoftForm, setDisplayVidoeSoftForm] = useState(false);
  const [videoSoftFormValue, setVideoSoftFormValue] = useState({});
  const [vsFormOriginal, setVsFormOriginal] = useState({});
  const [vsUsername, setVsUsername] = useState("");

  let loginUrl = "/api/vs/login";
  let logoutUrl = "/api/vs/logout";

  if (process.env.REACT_APP_IS_INDEPENDENT_DEV) {
    loginUrl = "http://localhost:5000" + loginUrl;
    logoutUrl = "http://localhost:5000" + logoutUrl;
  }

  const getVideoSoftStatus = (encrypted, auth, username, server) => {
    return fetch(loginUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: encrypted,
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.gateways?.length > 0) {
          setVideoSofts(data.gateways);

          VideoSoftOverlay.init(null, data.gateways);

          setVideoSoftServer(server);
          setVideoSoftAuth(auth);

          setVsUsername(username);

          setIsVideoSoftLoggedIn(true);

          return true;
        }
      })
      .catch((err) => {
        console.log("err", err);

        return false;
      });
  };

  const logoutUser = () => {
    if (vsUsername) {
      return fetch(logoutUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: vsUsername,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          setVsUsername("");
        })
        .catch((err) => {
          console.log("logout err", err);
        });
    }
  };

  useEffect(() => {
    if (isVideoSoftLoggedIn) {
      let socket = null;

      if (process.env.REACT_APP_IS_INDEPENDENT_DEV) {
        socket = io("http://localhost:5000", {
          query: {
            username: vsUsername,
          },
        });
      } else {
        socket = io({
          path: "/vs-socket",
          query: {
            username: vsUsername,
          },
        });
      }

      socket.connect();

      socket.on("vsUpdate", (data) => {
        
        setVideoSofts((previouVideoSofts) => {
          return previouVideoSofts.map((item) => {
            if (item.machineid === data.machineid) {
              // update map location
              if (data.latitude && data.longitude) {
                VideoSoftOverlay.refresh(data);
              }
              return data;
            }
           
            return item;
          });
        });
      });
    } else {
      // socket.disconnect();
    }
  }, [isVideoSoftLoggedIn]);

  const videoSoftLogout = () => {
    setIsVideoSoftLoggedIn(false);
    setVideoSoftAuth(null);
    setVideoSofts([]);

    logoutUser();

    VideoSoftOverlay.reset(true);

    Utils.removeCookie("videoSoft");
  };

  const goToVideoSoftForm = (title, machineid, reportInterval) => {
    setVideoSoftFormValue({ title, machineid, reportInterval });
    setVsFormOriginal({ title, machineid, reportInterval });
    setDisplayVidoeSoftForm(true);
  };

  return (
    <VideoSoftContext.Provider
      value={{
        videoSofts,
        videoSoftAuth,
        isVideoSoftLoggedIn,
        videoSoftServer,
        setVideoSoftServer,
        displayVidoeSoftForm,
        videoSoftFormValue,
        vsFormOriginal,
        vsUsername,
        setVsFormOriginal,
        setVideoSofts,
        setVideoSoftAuth,
        setIsVideoSoftLoggedIn,
        setDisplayVidoeSoftForm,
        setVideoSoftFormValue,
        getVideoSoftStatus,
        videoSoftLogout,
        goToVideoSoftForm,
      }}
    >
      {props.children}
    </VideoSoftContext.Provider>
  );
}

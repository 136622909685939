import React, { useContext } from "react";

import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

import {Button} from "@material-ui/core";
import CustomTooltip from "@Components/CustomTooltip";

import Language from "sccLanguage";
import Permission from "sccPermission";
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";
import VideoSoft from "@videoSoft/scripts/VideoSoft";

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

const videoSoftObject = {
  tab: "videoSoft",
  shortTitle: "video",
  title: "VideoSoft",
  moduleName: "videoSoft",
  module: VideoSoft,
  imageClass: "play",
  extLink: false,
  isList: true,
  wrapperDisplayMode: MW_LIST_MODE,
  showFilter: false,
  showSearch: false,
  showFooter: true,
  verifyShownPermission: Permission.verify("device", "view"),
  mapLayer: VideoSoftOverlay,
};

export default function QuickVSButton({history}) {
  const [mwState] = useContext(ModuleWrapperContext);

  const handleClick = () => {
    mwState.openModuleWrapperPopper(videoSoftObject);
  };

  if(history) return null;

  return (
    <div>
      <Button
        className="mapToolsBtn btn btn-default"
        variant="contained"
        color="default"
        onClick={handleClick}
        startIcon={
          <CustomTooltip
            title={Language.translate("VideoSoft")}
            arrow
            placement="top"
          >
            <VideoLibraryIcon />
          </CustomTooltip>
        }
      ></Button>
    </div>
  );
}

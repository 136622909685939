import { defaults as defaultControls } from "ol/control";
const olMap = require("ol/Map").default;
const olView = require("ol/View").default;
const olProj = require("ol/proj");
const olLayerGroup = require("ol/layer/Group").default;
const olCoordinate = require("ol/coordinate");
const olInteractionDraw = require("ol/interaction/Draw").default;
const olInteractionModify = require("ol/interaction/Modify").default;
const olInteractionTranslate = require("ol/interaction/Translate").default;
const olInteractionDragBox = require("ol/interaction/DragBox").default;
const olStyleStyle = require("ol/style/Style").default;
const olStyleStroke = require("ol/style/Stroke").default;
const olFeature = require("ol/Feature").default;
const olEventCondition = require("ol/events/condition");
const olStyle = require("ol/style/IconImageCache").shared;
const olOverlay = require("ol/Overlay").default;
const olExtent = require("ol/extent");
const Language = require("sccLanguage").default;
const olStyleFill = require("ol/style/Fill").default;

const log = require("loglevel");

// increasing the icon image cache size
olStyle.setSize(1000);
const minZoom = 3;
const maxZoom = 19;
const defaultViewOptions = {
  center: [0, 0],
  zoom: 3,
  extent: olProj.get("EPSG:3857").getExtent(),
  minZoom: minZoom,
  maxZoom: maxZoom,
  constrainResolution: true,
};

const mapExtent = {
  minLongitude: -180,
  maxLongitude: 180,
  minLatitude: -85,
  maxLatitude: 85,
};

//const olInteractionSelect= require("ol/interaction/select").default;
const _ = require("lodash");

//var Language = require("sccLanguage").default;
const Utils = require("sccUtils").default;

// holds whether or not drawing intraction is active
let drawInteraction = null;

// holds whether or not modifying intraction is active
let modifyIntraction = null;

// holds whether or not modifying intraction is active
let translateIntraction = null;

// Set the localized titles
let baseMapTitle = Language.translate("Base Maps");
let overlaysTitle = Language.translate("Overlays");

class OlMap {
  constructor(options) {
    this.moduleName = "map";
    this._activeLayers = {};
    this.options = options;
    this.map = null;
    this.overlays = null;
    this.baseLayers = null;
    this.mapQuickZoomInLevel = 16;
    this.evtType = null;
    this.editMode = null;
    this.currentMapsAPI = null;
    this.inclusiveActiveStatesFromGeofenceFormComponent = {};
    this.searchCoordMarkerOverlay = null;
    // holds functions to run upon moveend event of the map
    this.moveEndEvents = {};

    // holds custom click event functions
    this.clickEvents = {};

    // holds functions to run upon zoom change event of the map
    this.zoomChangeEvents = {};
  }

  static thePublicImage = null;

  init($scope) {
    if ($scope) {
      this.$scope = $scope;
      $scope.OlMap = this;
    }

    const $this = this;
    window.googleMapsAPIloaded = false;
    const googleMapLicenseInit = require("sccGoogleMapInit");
    window.olMap = this;
    googleMapLicenseInit.initGoogleMapLicense();
    return this.loadActiveLayers()
      .then(function () {
        return $this.initMaps();
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  /**
   * returns the options value for a given key, or all options if no key provided
   * @param {String | null} key key to return from options or null
   * @return {Any} the options value for the given key
   */
  getOptions(key) {
    const value = key ? this.options[key] : this.options;
    return value;
  }

  /**
   * gets the active map layers for the current user
   *
   */
  loadActiveLayers() {
    const options = {
      url: Utils.apiUrlPrefix + "/map_layer",
      method: "GET",
      data: {},
    };

    const $this = this;
    return Utils.httpRequestHandler(options).then(function (response) {
      $this._activeLayers = response.data;
      return $this._activeLayers;
    });
  }

  initMaps() {
    if (window.name != "History" || !window.name.includes("Extended"))
      addGoogleMapLicenseCheckScript();

    const $this = this;
    this.overlays = new olLayerGroup({
      title: overlaysTitle,
      layers: [],
    });

    this.baseLayers = new olLayerGroup({
      title: baseMapTitle,
      layers: [],
    });

    this.map = new olMap({
      layers: [$this.baseLayers, $this.overlays],
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
      view: new olView(defaultViewOptions),
      target: $this.options.mapDiv,
      controls: defaultControls({ attribution: false }),
    });

    const OlMapBaseLayer = require("./OlMapBaseLayer.js").default;

    this.BaseLayer = new OlMapBaseLayer({
      map: this.map,
      baseLayers: this.baseLayers,
    });

    window.olMap = this;

    const setupBottomLeftElements = () => {
      //timeout loop. The bottom left elements are prevented from being set up
      //until the elements have fully loaded
      if (
        document.getElementsByClassName("mapBottomLeftElements").length &&
        window.olMap.map != null
      ) {
        window.olMap.setupCoordinateDisplayControl();
        window.olMap.setupScaleLineControl();
        window.olMap.setupAttributionControl();
      } else {
        setTimeout(setupBottomLeftElements, 50);
      }
    };

    setTimeout(setupBottomLeftElements, 700);
    this.setupSelectionInteraction();

    const OlMapMeasure = require("./OlMapMeasure.js").default;
    this.Measure = new OlMapMeasure(this);

    return Promise.resolve();
  }

  doAfterGMapsAPIloadAttempted() {
    if (window.initMaps && window.olMap.BaseLayer != null) {
      window.olMap.BaseLayer.init();
      if (window.googleMapsAPIloaded && !window.invalidGoogleMapKey) {
        window.googleMap = require("sccGoogleMap");
        window.googleMap.initGoogleMaps();
      }

      window.olMap.setupMapCenter();
      window.olMap.setMapEvents();
      window.olMap.modifyInteractionControls();

      window.olMap.setupContextMenu();
      window.olMap.setViewEvents();
      window.olMap.loadMapLayers();
      window.olMap.setupZoomControl();
    } else {
      setTimeout(window.olMap.doAfterGMapsAPIloadAttempted, 50);
    }
  }

  handleOverlayObjWhenOlMapLoaded(overlay, func) {
    // When loading an extended map or a history map,
    // due to async loading of modules, sometimes the "addCustomOverlay" and "addMapOverlay" methods return an error since
    // this object (olMap) and its appropriate field variable objects haven't been fully initialized.
    // Therefore, this method executes the tasks pertaining to the overlay object once the olmap object and its field objects
    // have been fully initialized.

    if (window.olMap && !window.olMap.overlays) {
      //calls itself
      //every 50 milliseconds until olMap's overlays field has initialized appropriately.
      setTimeout(function () {
        window.olMap.handleOverlayObjWhenOlMapLoaded(overlay, func);
      }, 50);
    } else {
      func(overlay);
    }
  }

  /**
   * adds custom vector overlay layer to the map
   * @param {Object} overlay the overlay layer to be added to the map
   */
  addCustomOverlay(overlay) {
    var pushToOverlays = function (overlay) {
      var overlaysLayer = window.olMap.overlays.getLayers();
      overlaysLayer.push(overlay);
    };

    this.handleOverlayObjWhenOlMapLoaded(overlay, pushToOverlays); //pushes overlay object to overlays array through an async timeout loop
  }

  /**
   *
   * removes custom vector overlay layer from the map
   *
   * @param {Object} overlay the overlay layer to be removed from the map
   *
   */
  removeCustomOverlay(overlay) {
    var removeFromOverlays = function (overlay) {
      var overlaysLayer = window.olMap.overlays.getLayers();
      overlaysLayer.array_.splice(overlaysLayer.array_.indexOf(overlay), 1);
    };

    window.olMap.handleOverlayObjWhenOlMapLoaded(overlay, removeFromOverlays); //removes overlay object from overlays array through an async timeout loop
  }

  /**
   * Adds a new overlay to the map
   * This is different from vector layers that are added to overlay layer group
   */
  addMapOverlay(overlay) {
    var addOverlayToMap = function (overlay) {
      window.olMap.map.addOverlay(overlay);
    };
    this.handleOverlayObjWhenOlMapLoaded(overlay, addOverlayToMap); //adds overlay object to map through an async timeout loop
  }

  setupMapCenter() {
    log.debug("setting up map center");
    if (!this.options.mapCentering) return;

    const UserSetting = require("sccUserSetting").default;
    const longitude =
      Math.round(UserSetting.get("map_longitude") * 1000) / 1000;
    const latitude = Math.round(UserSetting.get("map_latitude") * 1000) / 1000;
    const zoomLevel = UserSetting.get("map_zoom_level");
    const coord = this.transformToMapCoordinate([longitude, latitude]);
    this.setMapView(coord, zoomLevel);
  }

  /**
   * transforms "EPSG:4326" coordinate format to "EPSG:3857"
   * @param {Array} coord coordinate array
   * @return {Array} new coordinate
   */
  transformToMapCoordinate(coord) {
    return olProj.transform(coord, "EPSG:4326", "EPSG:3857");
  }

  /**
   * sets up map events
   */
  setMapEvents() {
    log.debug("setting up map events");
    if (!this.options.events) return;

    _.each(this.options.events, (event) => {
      this.map.on(event.name, event.handlerFunction, this);
    });

    this.map.on("pointerdrag", () => {
      //updates google map center on drag
      var coord = this.getCenter();
      if (window.googleMapsAPIloaded && !window.invalidGoogleMapKey)
        require("sccGoogleMap").setCenter(coord);
    });

    this.map.on("moveend", () => {
      //updates google map center and zoom on scroll change
      var zoom = this.getZoom();
      var coord = this.getCenter();

      if (window.googleMapsAPIloaded && !window.invalidGoogleMapKey) {
        require("sccGoogleMap").setZoom(zoom);
        require("sccGoogleMap").setCenter(coord);
        require("sccGoogleMap").checkForMaxZoom(coord, zoom);
      }
    });
  }

  modifyInteractionControls(map) {
    //removes map inertia for ol maps. Thus, map stops in position just when drag-pan event has finished
    if (!map) {
      map = this.map;
    }

    map.getInteractions().array_[2].kinetic_.minVelocity_ = 9999; //removes map inertia by putting in an impossibly high minimum
    //velocity for map inertia to occur
    map.getInteractions().array_[7].duration_ = 50; //modifies the mouse wheel zoom duration to be much more instant
    map.getInteractions().array_[7].timeout_ = 0; //removes delay from mouse wheel zoom
  }

  setupContextMenu() {
    if (!this.options.contextMenu) return;
    const $this = this;
    const containerId = this.options.contextMenu.containerId;
    const offset = this.options.contextMenu.offset || [0, 0];
    const container = document.getElementById(containerId);
    if (!container)
      throw new Error(
        "Could not find the context menu container: " + containerId
      );

    this.contextMenuOverlay = new olOverlay({
      element: container,
      offset: offset,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    this.addMapOverlay(this.contextMenuOverlay);

    const {
      default: MapRightClickMenu,
    } = require("../components/MapRightClickMenu/index.js");
    MapRightClickMenu.publicInstance.addContextMenuItems();

    this.map.getViewport().addEventListener("contextmenu", function (evt) {
      evt.preventDefault();

      const coord = $this.map.getEventCoordinate(evt);
      $this.options.contextMenu.eventCoordinate = coord;
      $this.contextMenuOverlay.setPosition(coord);

      //REFRESH REACT CONTEXTMENU COMPONENT
      MapRightClickMenu.updateMapRightClickMenu();
    });
  }

  addContextMenuItem(options) {
    this.options.contextMenu.items = this.options.contextMenu.items || [];
    this.options.contextMenu.items.push(options);
  }

  closeContextMenu() {
    this.options.contextMenu && this.contextMenuOverlay.setPosition(null);
  }

  /**
   * sets up map view events
   */
  setViewEvents() {
    this.map.getView().on("change:resolution", zoomChangeHandler, this);
  }

  /**
   * sets map center and zoom level programatically
   * @param {Array} coord center coordinate
   * @param {Number} zoom zoom level
   */
  setCenterAndZoom(coord, zoom) {
    const view = window.olMap.map.getView();
    if (!view) {
      throw new Error("could not get view object of the map");
    }
    if (!zoom)
      //if zoom not passed, keeps current zoom
      zoom = window.olMap.getZoom();

    view.animate({
      center: coord,
      zoom: zoom,
      duration: 1000,
    });
  }

  setMapView(coord, zoom) {
    const olViewOptions = _.clone(defaultViewOptions);
    _.assign(olViewOptions, {
      center: coord,
      zoom: zoom,
    });

    var view = new olView(olViewOptions);
    view.fit(view.calculateExtent(this.map.getSize()));
    this.map.setView(view);
    //the following two functions are called because the "setView" function above of open layers lib keeps setting the zoom at (zoom-5) and does not reliably set center.
    //This occurs when google maps API is the active API when loading page and while google maps zoom is correctly set, open layers glitches out
    view.setZoom(zoom);
    view.setCenter(coord);
  }

  zoomToExtent(extent) {
    const $this = this;
    this.map.getView().fit(extent, {
      maxZoom: $this.mapQuickZoomInLevel,
      duration: 1000,
      padding: [40, 40, 40, 40], // iconDiameter from DeviceOverlay
    });
  }

  getExtent() {
    return this.map.getView().calculateExtent(this.map.getSize());
  }

  loadMapLayers() {
    if (document.getElementsByClassName("ol-attribution")[0])
      //removes pre-mature duplicate ol-attribution element
      document.getElementsByClassName("ol-attribution")[0].remove();
    const $this = this;
    _.each(this._activeLayers.result, function (layer) {
      if (layer.is_base_layer == "1") {
        $this.BaseLayer.add(layer);
      } else {
        //$this.addOverlay(layer.code);
      }
    });
    $this.BaseLayer.setDefaultMap(); //check if there is a default map to display, if not assign one
  }

  /**
   * sets up the coordinate display panel on the map if provided
   */
  setupCoordinateDisplayControl() {
    log.debug("setting up coordinate display control");
    if (!this.options.coordinateDisplay) return;

    const elementId =
      require("sccOlMapNew").default.options.coordinateDisplay.elementId;
    const olControlMousePosition = require("ol/control/MousePosition").default;
    const mousePositionControl = new olControlMousePosition({
      coordinateFormat: require("sccOlMapNew").default.formatCoordinate,
      projection: "EPSG:4326",
      // comment the following line to have the mouse position
      // be placed within the map.
      target: document.getElementById(elementId),
      undefinedHTML: "N/A",
    });
    window.olMap.map.addControl(mousePositionControl);
    log.debug("coord display control finished setting up");
  }

  fullZoomOut() {
    const view = this.map && this.map.getView();
    if (view == null) return;

    const zoom = view.getZoom();
    if (zoom <= minZoom) return;

    view.setZoom(minZoom);
  }

  setZoom(zoom) {
    const view = this.map && this.map.getView();
    view.setZoom(zoom);
  }

  /**
   * sets up zoom buttons if provided in the options list
   */
  setupZoomControl() {
    if (!this.options.zoomDisplay) return;

    const elementId = this.options.zoomDisplay.elementId;
    const olControlZoom = require("ol/control/Zoom").default;
    const zoom = new olControlZoom({
      target: document.getElementById(elementId),
    });
    this.map.addControl(zoom);
  }
  /**
   *  gets zoom levels of map and adds 1 when button clicked
   */
  zoomIn() {
    const view = this.map.getView();
    const zoom = view.getZoom();
    if (zoom === maxZoom) return;
    view.animate({
      zoom: zoom + 1,
      duration: 200,
    });

    if (window.googleMapsAPIloaded && !window.invalidGoogleMapKey) {
      const googleMap = require("sccGoogleMap");
      googleMap.zoomIn();
    }
  }
  /**
   *  gets zoom levels of map and minuses 1 when button clicked
   */
  zoomOut() {
    const view = this.map.getView();
    const zoom = view.getZoom();
    if (zoom === minZoom) return;
    view.animate({
      zoom: zoom - 1,
      duration: 200,
    });

    if (window.googleMapsAPIloaded && !window.invalidGoogleMapKey) {
      const googleMap = require("sccGoogleMap");
      googleMap.zoomOut();
    }
  }

  /**
   * sets up scale line display panel if provided in the options list
   */
  setupScaleLineControl() {
    if (!this.options.scaleLineDisplay) return;

    const elementId = this.options.scaleLineDisplay.elementId;
    const olControlScaleLine = require("ol/control/ScaleLine").default;
    const scaleLine = new olControlScaleLine({
      target: document.getElementById(elementId),
    });

    window.olMap.map.addControl(scaleLine);
  }

  //TODO: add attribution div to the map
  setupAttributionControl() {
    if (!this.options.attributionDisplay) return;

    const elementId = this.options.attributionDisplay.elementId;
    const olControlAttribution = require("ol/control/Attribution").default;
    const attribution = new olControlAttribution({
      collapsible: false,
      target: document.getElementById(elementId),
    });
    window.olMap.map.addControl(attribution);
  }

  setupSelectionInteraction() {
    log.debug("setting up map feature selection interaction");

    if (!this.options.selectionInteraction) return;

    const $this = this;
    const mapOverlay = this.options.selectionInteraction.mapOverlay;
    this.map.on("click", function (mapClickEvent) {
      const options = {
        clickCoordinate: mapClickEvent.coordinate,
        popUpPosition: "right-center",
        offset: [30, 0],
      };

      $this.closeContextMenu();

      var features = $this.map.getFeaturesAtPixel(mapClickEvent.pixel);
      if (features.length > 0) {
        //REFRESH REACT CONTEXTMENU COMPONENT
        const {
          default: DeviceDataDisplay,
        } = require("../../device/components/DataDisplay");
        DeviceDataDisplay.updateFeatureSelected();

        const {
          default: HistoryTrailDataDisplay,
        } = require("../../device/components/HistoryTrailDataDisplay");
        HistoryTrailDataDisplay.updateFeatureSelected();

        const {
          default: DeviceClusterDisplay,
        } = require("../../device/components/ClusterDisplay");
        DeviceClusterDisplay.updateFeatureSelected();

        const {
          default: PoiDataDisplay,
        } = require("../../poi/components/DataDisplay");
        PoiDataDisplay.updateFeatureSelected();

        const {
          default: PoiClusterDisplay,
        } = require("../../poi/components/ClusterDisplay");
        PoiClusterDisplay.updateFeatureSelected();

        const {
          default: GeofenceDataDisplay,
        } = require("../../geofence/components/DataDisplay");
        GeofenceDataDisplay.updateFeatureSelected();

        const {
          default: videoSoftDataDisplay,
        } = require("../../video_soft/components/DataDisplay/videoSoftDataDisplay.js");
        videoSoftDataDisplay.updateFeatureSelected();

        const {
          default: videoSoftClusterDisplay,
        } = require("../../video_soft/components/ClusterDisplay");
        videoSoftClusterDisplay.updateFeatureSelected();

        // const { default: SelectedDeviceContextProvider } = require("../../device/components/context/SelectedDeviceContext.js");
        // SelectedDeviceContextProvider.updateFeatureSelected();

        var mousePointX = mapClickEvent.pixel[0];
        var mousePointY = mapClickEvent.pixel[1];
        var screenWidth = mapClickEvent.originalEvent.view.innerWidth;
        var screenHeight = mapClickEvent.originalEvent.view.innerHeight;
        var widthDifference = screenWidth - mousePointX;
        var heightDifference = screenHeight - mousePointY;

        // LEFT SIDE MAP
        if (mousePointX < screenWidth / 2) {
          //TOP MAP
          if (mousePointY < screenHeight / 2) {
            if (mousePointX < 150) {
              if (mousePointY < 150) {
                options.popUpPosition = "top-left";
                options.offset = [-15, 30];
              } else {
                options.popUpPosition = "center-left";
                options.offset = [30, 0];
              }
            } else {
              options.popUpPosition = "top-center";
              options.offset = [0, 30];
            }
            // BOTTOM MAP
          } else {
            if (mousePointX < 150) {
              if (heightDifference < 150) {
                options.popUpPosition = "bottom-left";
                options.offset = [-15, -30];
              } else {
                options.popUpPosition = "center-left";
                options.offset = [30, 0];
              }
            } else {
              options.popUpPosition = "bottom-center";
              options.offset = [0, -30];
            }
          }
        } else {
          // RIGHT SIDE MAP
          //TOP MAP
          if (mousePointY < screenHeight / 2) {
            if (widthDifference < 150) {
              if (mousePointY < 150) {
                options.popUpPosition = "top-right";
                options.offset = [15, 30];
              } else {
                options.popUpPosition = "center-right";
                options.offset = [-30, 0];
              }
            } else {
              options.popUpPosition = "top-center";
              options.offset = [0, 30];
            }
            // BOTTOM MAP
          } else {
            if (widthDifference < 150) {
              if (heightDifference < 150) {
                options.popUpPosition = "bottom-right";
                options.offset = [15, -30];
              } else {
                options.popUpPosition = "center-right";
                options.offset = [-30, 0];
              }
            } else {
              options.popUpPosition = "bottom-center";
              options.offset = [0, -30];
            }
          }
        }
        if (window.olMap.editMode && window.olMap.editMode != "dragMap") {
          mapOverlay.selectFeature(features, options);
        } else if (!window.olMap.editMode) {
          mapOverlay.selectFeature(features, options);
        }
      } else {
        mapOverlay.deselectAllFeatures();
      }

      _.each($this.clickEvents, (func) => {
        func(mapClickEvent);
      });
    });
  }

  /**
   * registers a new custom event function
   * @param {String} name event list storage name
   * @param {String|Number} id unique ID for event function
   * @param {Function} func event function to run
   */
  registerEvent(name, id, func) {
    if (id == null)
      throw new Error("Need a uniqe ID to register a new moveend event");
    this[name][id] = func;
  }

  /**
   * unregisters an event function
   * @param {String} name event list storage name
   * @param {Array|String|Number} id unique ID of the event function
   */
  unregisterEvent(name, id) {
    id = _.concat([], id);
    if (id == null)
      throw new Error("Need a uniqe ID to unregister a moveend event");
    this[name] = _.omit(this[name], id);
  }

  /**
   * converts the lonlat value that has been read from the DB to the user-defined projection
   * @param {*} coord coordinate array [lon, lat]
   * @return {String} formatted user-defined coor
   */
  transformCoordinate(coord) {
    const coordinate = this.formatCoordinate(coord);

    // formatCoordinate returns coordinate in 'lat, lon' format
    // need to convert it to OpenLayers statndard 'lon, lat' format
    const reverseCoord = coordinate.split(",");
    let coordOut = reverseCoord;
    // no need to reverse if coordinate array has length 1, e.g., USNG and MGRS format
    if (reverseCoord.length > 1) {
      coordOut = [reverseCoord[1], reverseCoord[0]];
    }
    return coordOut;
  }

  /**
   * formats the coordinate based on user's selected settings
   * @param {Array} coord coordinate to be formatted
   * @param {String} sourceProjection if provided would be used as source coordinate, otherwise "EPSG:4326" is used
   * @return {String} formatted corrdinate
   */
  formatCoordinate(sourceCoord, sourceProjection) {
    const usng = require("usng.js");
    const converter = new usng.Converter();
    const UserSetting = require("sccUserSetting").default;
    let coord = sourceCoord;
    // converts the projection to "EPSG:4326" if sourceProjection is not the default
    if (sourceProjection) {
      coord = olProj.transform(sourceCoord, sourceProjection, "EPSG:4326");
    }

    const longitude = coord[0];
    const latitude = coord[1];

    let out = "N/A";
    let newCoord;
    const coordFormat = UserSetting.get("lonlat_format");
    switch (coordFormat) {
      case "MGRS":
        try {
          out = converter.LLtoUSNG(latitude, longitude, 6);
        } catch (err) {
          out = "N/A";
        }
        break;
      case "DMS":
        out = olCoordinate.toStringHDMS(coord, 2);
        out = _.replace(out, "S", "S,");
        out = _.replace(out, "N", "N,");
        break;
      case "MinDec":
        out =
          DegDec2MinDec(longitude, "lon") +
          ", " +
          DegDec2MinDec(latitude, "lat");
        break;
      case "EPSG:900913":
        coord = _.map(coord, (coordinate) => {
          return Number(coordinate);
        });
        newCoord = olProj.transform(coord, "EPSG:4326", "EPSG:900913");
        out = olCoordinate.toStringXY(newCoord, 5);
        
        break;
      case "EPSG:4326":
        coord = _.map(coord, (coordinate) => {
          return Number(coordinate);
        });
        out = olCoordinate.toStringXY(coord, 5);
        break;
      default:
        new Error("Cannot recognize the destination coordinate format");
    }

    const reverseOut = out.split(",");
    if (reverseOut.length > 1 && coordFormat != "EPSG:900913" ) {
      if (coordFormat == "DMS") {
        out = reverseOut[0].trim() + ", " + reverseOut[1].trim();
      } else {
        out = reverseOut[1].trim() + ", " + reverseOut[0].trim();
      }
    }

    return out;
  }

  convertLonlatUser2Map(coord, index, sourceFormat) {
    let newCoord = null;
    const usng = require("usng.js");
    const converter = new usng.Converter();
    const UserSetting = require("sccUserSetting").default;
    var coordFormat;
    if (!sourceFormat)
      //if format not defined, current format selected in user settings used
      coordFormat = UserSetting.get("lonlat_format");
    else coordFormat = sourceFormat;

    switch (coordFormat) {
      case "MGRS":
        newCoord = converter.USNGtoLL(coord[0], {});
        return [newCoord.lon, newCoord.lat];
      case "DMS":
        newCoord = [null, null];
        newCoord[index] = DMS2DegDec(coord[index]);
        break;
      case "MinDec":
        newCoord = [null, null];
        newCoord[index] = MinDec2DegDec(coord[index]);
        break;
      case "EPSG:900913":
        coord = _.map(coord, co => Number(co));
        newCoord = olProj.transform([coord[1], coord[0]], "EPSG:900913", "EPSG:4326");
        break;
      default:
        newCoord = coord;
    }

    return newCoord[index];
  }

  /**
   * gets the name of the coordinate system selected by the user
   * @return {String} formatted coordinate name
   */
  getUserLonlatFormat() {
    const UserSetting = require("sccUserSetting").default;
    if (UserSetting.get("lonlat_format") == "MGRS") {
      return Language.translate("Location") + " (MGRS)";
    } else {
      return Language.translate("Location") + " (Lat, Long)";
    }
  }

  getZoom() {
    const view = this.map && this.map.getView();
    if (view == null) return 0;
    return Math.round(view.getZoom());
  }

  /**
   * starts drawing a geofence feature
   * @param {} type
   * @param {} source
   * @param {} options
   */
  startDrawing(type, source, options) {
    const $this = this;
    this.editMode = "reshapeMap";
    if (["Point", "Polygon", "Circle", "LineString"].indexOf(type) > -1) {
      drawInteraction = new olInteractionDraw({
        stopClick: true,
        snapTolerance: 3,
        source: source,
        type: type,
        wrapX: false,
        condition: function (evt) {
          // prevent user to click outside of map when drawing
          const transCoord = olProj.transform(
            evt.coordinate,
            "EPSG:3857",
            "EPSG:4326"
          );
          return (
            transCoord[0] <= mapExtent.maxLongitude &&
            transCoord[0] >= mapExtent.minLongitude &&
            transCoord[1] <= mapExtent.maxLatitude &&
            transCoord[1] >= mapExtent.minLatitude
          );
        },
      });

      drawInteraction.on("drawstart", (evt) => {
        const GeofenceOverlay = require("sccGeofenceOverlay").default;
        GeofenceOverlay.floatingFeatureOnMap = true;
        options && options.drawStarted && options.drawStarted(evt.feature);
      });

      drawInteraction.on("drawend", (evt) => {
        this.editMode = null;
        options && options.drawFinished && options.drawFinished(evt.feature);
        $this.endDrawing();
      });

      /**
       * Rectangles are made with dragbox interaction.
       * In such case, features are made using geometry of the box and added to the source
       */
    } else if (type == "Rectangle") {
      drawInteraction = new olInteractionDragBox({});

      // holds the feature being drawn
      let feature = null;

      drawInteraction.on("boxstart", (evt) => {
        const GeofenceOverlay = require("sccGeofenceOverlay").default;
        GeofenceOverlay.floatingFeatureOnMap = true;
        const geom = evt.target.getGeometry();
        feature = new olFeature({
          geometry: geom,
        });

        source.addFeature(feature);

        feature.setStyle(
          new olStyleStyle({
            condition: olEventCondition.platformModifierKeyOnly,
            stroke: new olStyleStroke({
              color: [0, 145, 255, 1],
              width: 2,
            }),
            fill: new olStyleFill({
              color: [255, 255, 255, 0.6],
            }),
          })
        );

        options && options.drawStarted && options.drawStarted(feature);
      });

      drawInteraction.on("boxdrag", (evt) => {
        const geom = evt.target.getGeometry();
        feature.setGeometry(geom);
      });

      drawInteraction.on("boxend", (evt) => {
        this.editMode = null;
        options && options.drawFinished && options.drawFinished(feature);
        $this.endDrawing();
      });
    }

    this.map.addInteraction(drawInteraction);
  }

  /**
   * ends geofence drawing
   */
  endDrawing() {
    //const OlMap= require("sccOlMapNew");
    this.editMode = null;
    this.map.removeInteraction(drawInteraction);
  }

  startModifying(source, options) {
    modifyIntraction = new olInteractionModify({
      source: source,
      wrapX: false,
    });

    modifyIntraction.on("modifyend", (evt) => {
      log.debug("modify end", evt);
      // Keep this event type 'modifyend' to use for geofence re-shaping
      this.evtType = evt.type;
      options && options.modifyFinished && options.modifyFinished();
    });

    this.map.addInteraction(modifyIntraction);
  }

  endModifying() {
    // Empty this event type when geofence re-shaping is finished
    this.evtType = null;
    this.map.removeInteraction(modifyIntraction);
  }

  startTranslating(features, options) {
    translateIntraction = new olInteractionTranslate({
      features: features,
      wrapX: false,
    });

    translateIntraction.on("translating", (evt) => {
      this.evtType = evt.type;
      options && options.translating && options.translating();
    });

    translateIntraction.on("translateend", () => {
      options && options.translatedFinished && options.translatedFinished();
    });

    this.map.addInteraction(translateIntraction);
  }

  endTranslating() {
    this.evtType = null;
    this.map.removeInteraction(translateIntraction);
  }

  /**
   * adds a marker layer and markers at the locations provided
   * @param {Array} coordinates coordinates collection
   * @param {*} center center of the map to be set
   * @param {*} zoom zoom level to be set
   */
  addMarker(coordinates, center, zoom) {
    log.debug("adding a new marker", center, zoom);
    const $this = this;
    const olFeature = require("ol/Feature").default;
    const olGeomPoint = require("ol/geom/Point").default;
    const iconFeatures = _.map(coordinates, (coord) => {
      const newCoord = $this.transformToMapCoordinate(coord);
      return new olFeature({
        geometry: new olGeomPoint(newCoord),
      });
    });

    const olLayerVector = require("ol/layer/Vector").default;
    const olSourceVector = require("ol/source/Vector").default;
    const vectorSource = new olSourceVector({
      features: iconFeatures, //add an array of features
    });
    const olStyleStyle = require("ol/style/Style").default;
    const olStyleStroke = require("ol/style/Stroke").default;
    const olStyleFill = require("ol/style/Fill").default;
    const olStyleRegularShape = require("ol/style/RegularShape").default;
    const stroke = new olStyleStroke({ color: "black", width: 2 });
    const fill = new olStyleFill({ color: "red" });
    const style = new olStyleStyle({
      image: new olStyleRegularShape({
        fill: fill,
        stroke: stroke,
        points: 4,
        radius: 10,
        radius2: 3,
        angle: 0,
      }),
    });
    const vectorLayer = new olLayerVector({
      source: vectorSource,
      style: style,
    });
    this.addCustomOverlay(vectorLayer);
    // this.setCenterAndZoom(this.transformToMapCoordinate(center), zoom);
  }

  addSearchCoordMarker(coordinates, center, zoom) {
    log.debug("adding a new marker", coordinates, center, zoom);

    const olFeature = require("ol/Feature").default;
    const olGeomPoint = require("ol/geom/Point").default;
    const iconFeatures = _.map(coordinates, (coord) => {
      const newCoord = window.olMap.transformToMapCoordinate(coord);

      return new olFeature({
        geometry: new olGeomPoint(newCoord),
        name: "coordSearchMarker",
        coordinates: newCoord,
      });
    });

    const olLayerVector = require("ol/layer/Vector").default;
    const olSourceVector = require("ol/source/Vector").default;
    const vectorSource = new olSourceVector({
      features: iconFeatures, //add an array of features
    });

    const olStyleStyle = require("ol/style/Style").default;
    const olStyleIcon = require("ol/style/Icon").default;
    const style = new olStyleStyle({
      image: new olStyleIcon({
        src: require("../../image/images/coordinateSearchMarker.svg").default,
        scale: 0.09,
        anchor: [0.5, 1],
      }),
    });

    const vectorLayer = new olLayerVector({
      source: vectorSource,
      style: style,
    });
    window.olMap.searchCoordMarkerOverlay = vectorLayer;
    this.addCustomOverlay(vectorLayer);
    this.setCenterAndZoom(this.transformToMapCoordinate(center), zoom);
    window.olMap.addCoordSearchMarkerPopup();
  }

  addCoordSearchMarkerPopup() {
    window.olMap.map.on("click", (evt) => {
      window.olMap.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        if (feature.values_.name == "coordSearchMarker") {
          const coord = feature.values_.coordinates;
          window.olMap.options.contextMenu.eventCoordinate = coord;
          window.olMap.contextMenuOverlay.setPosition(coord);

          //REFRESH REACT CONTEXTMENU COMPONENT
          const {
            default: MapRightClickMenu,
          } = require("../components/MapRightClickMenu/index.js");
          MapRightClickMenu.updateMapRightClickMenu();
        }
      });
    });
  }

  showSearchCoordMarker(coord, center, zoom) {
    window.olMap.removeSearchCoordMarkerAndErrorMsg();
    window.olMap.addSearchCoordMarker(coord, center, zoom);
  }

  removeSearchCoordMarkerAndErrorMsg() {
    //resets error message as well, if displaying
    if (window.olMap.searchCoordMarkerOverlay) {
      window.olMap.removeCustomOverlay(window.olMap.searchCoordMarkerOverlay);
      window.olMap.searchCoordMarkerOverlay.setStyle(new olStyleStyle(null));
      window.olMap.searchCoordMarkerOverlay = null;
    }
  }

  getMapExtent() {
    return mapExtent;
  }

  getCenter() {
    return olProj.transform(
      window.olMap.map.getView().getCenter(),
      "EPSG:3857",
      "EPSG:4326"
    );
  }
}

/**
 * handler function for moveend event
 */
function moveEndHandler() {
  const $this = this;
  zoomendHandler(this);

  //run all registered events
  _.each(this.moveEndEvents, (func) => {
    func($this.map);
  });

  checkCornerOverflow(this);
}

/**
 * handler function for map zoom change event
 */
function zoomChangeHandler() {
  const $this = this;
  _.each($this.zoomChangeEvents, (func) => {
    func($this.map);
  });
}

/*
 *	Check Corner Overlow
 *
 *  If user drags map too far, or zooms out so that the edges of the map are overrun, adjust the map to prevent overflow
 */
function checkCornerOverflow(OlMap) {
  var map = OlMap;

  var view = map.getView();

  var zoom = view.getZoom();

  var mapExtent = view.calculateExtent(map.getSize());

  var viewExtent = view.getProjection().getExtent();

  var mapMinX = olExtent.getBottomLeft(mapExtent)[0];
  var mapMinY = olExtent.getBottomLeft(mapExtent)[1];
  var mapMaxX = olExtent.getTopRight(mapExtent)[0];
  var mapMaxY = olExtent.getTopRight(mapExtent)[1];

  var viewMinX = olExtent.getBottomLeft(viewExtent)[0];
  var viewMinY = olExtent.getBottomLeft(viewExtent)[1];
  var viewMaxX = olExtent.getTopRight(viewExtent)[0];
  var viewMaxY = olExtent.getTopRight(viewExtent)[1];

  var newExtent = [mapMinX, mapMinY, mapMaxX, mapMaxY];

  if (mapMinX < viewMinX) {
    newExtent[0] = viewMinX;
  }
  if (mapMinY < viewMinY) {
    newExtent[1] = viewMinY;
  }
  if (mapMaxX > viewMaxX) {
    newExtent[2] = viewMaxX;
  }
  if (mapMaxY > viewMaxY) {
    newExtent[3] = viewMaxY;
  }

  if (newExtent != mapExtent) {
    view.fit(newExtent);
    view.setZoom(zoom);
  }
}

/**
 * saves the current map center when map center is moved
 * Note: a 2 second delay is added to prevent too many requests when user is
 * continously moving the map and zooming in and out
 * @param {Object} map openlayer map object
 */
function zoomendHandler(OlMap) {
  const map = OlMap;
  clearTimeout(window.olMap.zoomendHandlerTimer);
  window.olMap.zoomendHandlerTimer = setTimeout(function () {
    const lonlat = olProj.transform(
      map.getView().getCenter(),
      "EPSG:3857",
      "EPSG:4326"
    );
    saveZoomCenter(map.getView().getZoom(), lonlat[0], lonlat[1]);
  }, 2000);
}

/**
 * stores the current zoom level and center lon/lat to the DB
 */
function saveZoomCenter(zoom, centerLon, centerLat) {
  var setting = {
    map_longitude: centerLon,
    map_latitude: centerLat,
  };

  if (typeof zoom === "number") {
    setting.map_zoom_level = zoom;
  }
  const UserSetting = require("sccUserSetting").default;
  return UserSetting.update(setting);
}

function DegDec2MinDec(coord, axis) {
  if (coord < 0) {
		direction = (axis == "lon") ? "W" : "S";
	} else {
		direction = (axis == "lon") ? "E" : "N";
	}
	coord = Math.abs(coord);
	var deg = Math.floor(coord);
	var min = rnd((coord - deg) * 60);
	var direction;

	return deg + "°" + min + direction;
}

function MinDec2DegDec(coord) {

  var start = 0;
  var end = coord.indexOf("°");
  var deg = coord.substring(start, end);
  deg = deg - 0;

  start = end + 1;
  end = coord.length - 1;
  var frac = coord.substring(start, end);
  frac = frac / 60;

  start = end;
  end = start + 1;
  var direction = coord.substring(start, end);
  var sign = direction == "W" || direction == "S" ? -1 : 1;

  return (deg + frac) * sign;
}

function DMS2DegDec(dmsStr) {
  var start = 0;
  var end = dmsStr.indexOf("°", start);
  var degree = Number(dmsStr.substring(start, end));

  start = end + 1;
  end = dmsStr.indexOf("′", start);
  var minute = Number(dmsStr.substring(start, end));

  start = end + 1;
  end = dmsStr.indexOf("″", start);
  var second = Number(dmsStr.substring(start, end));


  start = end + 1;
  end = start + 1;
  //var sign = (direction == "W" || direction == "S") ? -1 : 1;

  //var sign = (dmsStr.indexOf("W") > -1 || dmsStr.indexOf("S") > -1) ? -1 : 1;

  var sign = 1;
  if (dmsStr.indexOf("W") > -1 || dmsStr.indexOf("S") > -1) {
    sign = -1;
  }

  var result = ((second / 60 + minute) / 60 + degree) * sign;

  return result;
}

/*
 * Function: rnd
 * Rounds the input number to requested precision
 *
 * Parameters:
 * v - {float} input number
 * p - {integer} precision
 */
function rnd(num) {
  return Math.round(num * 100000) / 100000;
}

function addGoogleMapLicenseCheckScript() {
  window.initMaps = function () {
    window.googleMapsAPIloaded = true;
  };

  window.gm_authFailure = function () {
    //Google Maps API automatically searches for a method by the name "gm_authFailure" and
    //invokes it upon key invalidation.
    window.invalidGoogleMapKey = true;
    window.olMap.BaseLayer.giveOSMlayerAccessAndMakeVisible();
  };
}

export default new OlMap({
  coordinateDisplay: {
    elementId: "mainCoordDis",
  },
  zoomDisplay: {
    elementId: "olMapZoomButtons",
  },
  contextMenu: {
    containerId: "mapContextMenu",
  },
  scaleLineDisplay: {
    elementId: "scaleLineDIS",
  },
  attributionDisplay: {
    elementId: "mapAttrDisplay",
  },
  selectionInteraction: {
    mapOverlay: require("sccOlMapOverlay"),
  },
  layerSwitcherDisplay: {
    target: "switcherMenu",
    tipLabel: Language.translate("Map Layers"), // Optional label for button
  },
  events: [
    {
      name: "moveend",
      handlerFunction: moveEndHandler,
    },
  ],
  mapCentering: true,
  mapDiv: "mapW",
});
//module.exports.default= OlMap;

export { OlMap };

// export function getOptions(key) {
// 	return getTheStaticInstance().getOptions(key);
// }

// export function getTheStaticInstance() {
// 	if (OlMap.thePublicInstance == null) {
// 		OlMap.thePublicInstance = new OlMap();
// 	}
// 	return OlMap.thePublicInstance;
// };

// export default new OlMap();

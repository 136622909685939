import React from "react";

// Material-UI
import { IconButton } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

// Internal
import Language from "sccLanguage";
import CustomTooltip from "@Components/CustomTooltip";
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#fff",
  },

  clusterContiner: {
    height: 30,
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },

  zoomButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.colors.gray.dark,
    width: 20,
    height: 26,
    marginLeft: 3,
    "&:hover": {
      borderRadius: 0,
      backgroundColor: theme.palette.colors.gray.dark,
      "& $deviceDisplayIcon": {
        color: theme.palette.colors.black.main,
      },
    },
  },

  zoomIcon: {
    color: "#293239",
    color: alpha(theme.palette.colors.black.main, 0.85),
    width: 16,
    height: 16,
  },
}));

export default function ClusterDisplayButtonGroup({ gatewayIds }) {
  const classes = useStyles();

  const handleZoom = () => {
    const id = VideoSoftOverlay.getSelectedId();
    VideoSoftOverlay.centerAndZoomTo(gatewayIds);
    VideoSoftOverlay.hideClusterPopup();
  };

  return (
    <div className={classes.container}>
      <div className={classes.clusterContiner}>
        <CustomTooltip
          title={Language.translate("Zoom In")}
          arrow
          placement="top"
        >
          <IconButton className={classes.zoomButton} onClick={handleZoom}>
            <GpsFixedIcon className={classes.zoomIcon} />
          </IconButton>
        </CustomTooltip>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

// Material-UI
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Intervnal
import Language from "sccLanguage";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";

const useStyles = makeStyles((theme) => ({
  tsFilterContainer: {
    paddingTop: 16,
  },
  textField: {
    borderRadius: 0,
    width: 260,
    "& .MuiOutlinedInput-input": {
      padding: "12px",
      "& .MuiOutlinedInput-input:focus": {
        border: "1px solid red",
      },
    },
  },
  applyButton: {
    margin: 16,
  },
}));

const formatDateForCalendar = (date) => {
  const d = new Date(date * 1000);
  return (
    [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join(
      "-"
    ) +
    "T" +
    [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
  );
};

const TimestampFilter = ({ onFilterDate, filterSelection }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      start_date: undefined,
      end_date: undefined,
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.number()
        .typeError(Language.translate("Select a valid start date"))
        .positive()
        .lessThan(
          Yup.ref("end_date"),
          Language.translate("Start date must be before end date")
        )
        .required(),
      end_date: Yup.number()
        .typeError(Language.translate("Select a valid end date"))
        .positive()
        .moreThan(
          Yup.ref("start_date"),
          Language.translate("End date must be after start date")
        ),
    }),
    onSubmit: (values) => {
      onFilterDate([Number(values.start_date), Number(values.end_date)]);
    },
  });

  const handleDate = (e) => {
    if (e.target.name === "start_date") setStartDate(e.target.value);
    if (e.target.name === "end_date") setEndDate(e.target.value);
    formik.setFieldValue(e.target.name, moment(e.target.value).format("X"));
  };

  const cleanTimestampFilter = () => {
    formik.resetForm({});
    setStartDate(null);
    setEndDate(null);
    onFilterDate([]);
  };

  useEffect(() => {

    if (filterSelection?.report_timestamp?.length > 0) {
      setStartDate(formatDateForCalendar(filterSelection?.report_timestamp[0]));
      setEndDate(formatDateForCalendar(filterSelection?.report_timestamp[1]));

      formik.setFieldValue("start_date", filterSelection?.report_timestamp[0]);
      formik.setFieldValue("end_date", filterSelection?.report_timestamp[1]);
    }
  }, filterSelection);

  return (
    <form>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.tsFilterContainer}
      >
        <Grid item xs={12}>
          <CustomTextField
            type="datetime-local"
            className={classes.textField}
            label={`${Language.translate("Start Date")}*`}
            name="start_date"
            defaultValue={startDate}
            value={startDate}
            inputProps={{ max: endDate }}
            onChange={handleDate}
            onBlur={formik.handleBlur}
            error={
              formik.touched.start_date && Boolean(formik.errors.start_date)
            }
            helperText={Language.translate(
              formik.touched.start_date && formik.errors.start_date
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            type="datetime-local"
            className={classes.textField}
            label={`${Language.translate("End Date")}*`}
            name="end_date"
            defaultValue={endDate}
            value={endDate}
            inputProps={{ min: startDate }}
            onChange={handleDate}
            onBlur={formik.handleBlur}
            error={formik.touched.end_date && Boolean(formik.errors.end_date)}
            helperText={Language.translate(
              formik.touched.end_date && formik.errors.end_date
            )}
          />
        </Grid>
        <Grid item xs={12} className={classes.applyButton}>
          <CustomButton
            color="command"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={!_.isEmpty(formik.errors)}
            className={classes.applyButton}
          >
            {Language.translate("Apply")}
          </CustomButton>

          <CustomButton
            color="command"
            type="button"
            onClick={cleanTimestampFilter}
            disabled={!startDate || !endDate}
            className={classes.applyButton}
          >
            {Language.translate("reset")}
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default TimestampFilter;

import { default as OlMapOverlay } from "sccOlMapOverlay";
import VideoSoft from "./VideoSoft";
const _ = require("lodash");
const olProj = require("ol/proj");
const olStyleIcon = require("ol/style/Icon").default;
const olStyleStyle = require("ol/style/Style").default;
const olStyleStroke = require("ol/style/Stroke").default;
const olStyleFill = require("ol/style/Fill").default;
const olStyleText = require("ol/style/Text").default;
const olStyleRegularShape = require("ol/style/RegularShape").default;
const olFeature = require("ol/Feature").default;

// the diameter in pixels to use to size the icon
const iconDiameter = 40;

const videoIconBase64 =
  "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnpNOS41IDE2LjV2LTlsNyA0LjUtNyA0LjV6Ii8+PC9zdmc+";

const videoClusterIconBase64 =
  "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgMTAuNVY3YzAtLjU1LS40NS0xLTEtMUg0Yy0uNTUgMC0xIC40NS0xIDF2MTBjMCAuNTUuNDUgMSAxIDFoMTJjLjU1IDAgMS0uNDUgMS0xdi0zLjVsNCA0di0xMWwtNCA0eiIvPjwvc3ZnPg==";

export const defaultOptions = {
  id: "videoSoft_overlay",

  // overlay title
  title: "VideoSoft",

  zIndex: 150,

  // whether or not features of the layer are selectable
  isSelectable: true,

  /**
   * Contains clustering options and if provided layer would be clustered.
   */
  cluster: {
    popupContainerId: "olMapVideoSoftClusterPopup",
    offset: [iconDiameter / 2 + 5, -(35 + iconDiameter / 2)],
  },

  /**
   * Provides info for the popup window.
   * Popup would be disabled if this property is not provided
   */
  popup: {
    containerId: "olMapVideoSoftPopup",
    offset: [iconDiameter / 2 + 10, -(35 + iconDiameter / 2)],
  },
};

const defaultIconOptions = {
  anchor: [0.5, 0.5],
  anchorXUnits: "fraction",
  anchorYUnits: "fraction",
  opacity: 1,
};

class VideoSoftOverlay extends OlMapOverlay.OlMapOverlay {
  constructor(options) {
    super(options);
    this.moduleName = "videoSoft_overlay";
    this.videoSofts = [];
    this.hasInitialized = false;
  }

  init(options, vs) {
    if (!this.hasInitialized) {
      super.init(options);
      this.addOverlay();
    }

    if (vs?.length > 0) {
      let list = vs.flatMap((gateway) => {
        if (gateway.latitude && gateway.longitude) {
          gateway.id = gateway.machineid;

          return gateway;
        } else {
          return [];
        }
      });

      this.addFeature({
        data: list,
      });
    }

    this.hasInitialized = true;
  }

  addFeature(options) {
    const $this = this;
    const source = this.source;
    const dataArray = _.concat([], options.data);
    if (!dataArray.length) {
      return;
    }

    const features = _.filter(
      dataArray,
      (data) => {
        const id = $this.getId(data);
        if (id === null) {
          return false;
        }
        return true;
      },
      []
    ).map((data) => {
      const id = $this.getId(data);
      const feature = new olFeature({
        geometry: $this.buildFeatureGeometry(data),
        id: id,
        title: data.title || "NO TITLE",
        latitude: data.latitude,
        longitude: data.longitude,
      });

      feature.setId(id);

      // sets a property for feature overlay title
      feature.set("overlayId", $this.id);
      feature.set("olMap", $this.OlMap);

      return feature;
    }, []);

    source.addFeatures(features);
    return features;
  }

  getFeature(id) {
    // since the selected feature is removed from the main layer and added to the select icon layer
    // we search for the feature in both the main layer and in the select icon layer
    return (
      this.source.getFeatureById(id) || this.selectIconSource.getFeatureById(id)
    );
  }

  refresh(gateway) {
    try {
      if (gateway.latitude && gateway.latitude) {
        gateway.id = gateway.machineid;

        const feature = this.getFeature(gateway.id);

        if (feature) {
          if (
            gateway.latitude !== feature.values_?.latitude ||
            gateway.longitude !== feature.values_?.longitude
          ) {
            const geom = this.buildFeatureGeometry(gateway);
            feature.setGeometry(geom);
            // if feature is selected the select icon and popup needs to move as well

            this.updateSelectIconGeometry(gateway.id, geom);

            feature.changed();

            const {
              default: videoSoftClusterDisplay,
            } = require("../components/ClusterDisplay");
            videoSoftClusterDisplay.updateFeatureSelected();
          } else {
            console.log("videoSoft position not change, no need to update");
          }
        } else {
          // add new feature

          this.addFeature({ data: gateway });
        }
      }
    } catch (err) {}
  }

  /**
   * gets the style for showing a feature
   * @param {Object} feature feature that is being styled
   * @return {Object} style to show the feature
   * @override
   */
  getStyle(feature) {
    super.getStyle();
    const features = feature.get("features");
    var size = (features && features.length) || 1;

    if (size === 1) {
      const f = features ? features[0] : feature;

      return getSingularStyle(f);
    }

    return getClusterStyle(features);
  }

  /**
   * gets the style for select icon and selected feature
   * @param {Object} feature clone of the selected feature
   * @return {Object} style to show the feature
   * @override
   */
  getSelectStyle(feature) {
    super.getSelectStyle(feature);
    // if feature has id, then it is the selected feature and must be
    // styled as before.
    // if feature does not have id, then it is the select icon
    if (feature.getId()) {
      return getSingularStyle(feature);
    }

    //TODO: must change this to return selection style for pois
    return selectStyle;
  }

  getIconById(id) {
    return getIcon();
  }

  registerClickEvent(id, func) {
    this.OlMap.registerEvent("clickEvents", id, function (evt) {
      const coord = olProj.transform(evt.coordinate, "EPSG:3857", "EPSG:4326");
      func(coord);
    });
  }

  getFeatureData() {
    return this.videoSofts;
  }
}

const selectStyle = getSelectStyle();

function getSelectStyle() {
  const fill = new olStyleFill({ color: [0, 0, 128, 0.2] });
  const stroke = new olStyleStroke({
    color: "black",
    lineDash: [7, 7],
    width: 2,
  });
  const iconStyle = new olStyleStyle({
    image: new olStyleRegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: getSelectIconRadius(),
      angle: Math.PI / 4,
    }),
  });
  return iconStyle;
}

/**
 * gets the radius of selection icon
 */
function getSelectIconRadius() {
  // get the sqrt(2) times the diameter/2 since it is a square
  // adds 2 pixels to avoid intersecting the icon
  return (iconDiameter * Math.SQRT2) / 2 + 2;
}

const singularStyleCache = {};

function getSingularStyle(feature, videoSoft) {
  const text = getIconText(feature);
  const tag = getStyleCacheTag(feature, text);
  if (singularStyleCache[tag]) return singularStyleCache[tag];

  const icon = getIcon(videoSoft);
  const iconOptions = _.assign(
    {
      rotateWithView: false,
      //scale: scale,
      src: icon,
      scale: 1.3,
      color: "#000",
    },
    defaultIconOptions
  );

  singularStyleCache[tag] = [
    new olStyleStyle({
      image: new olStyleIcon(iconOptions),
      text: new olStyleText({
        font: "16px Courier New,Calibri,sans-serif",
        fill: new olStyleFill({ color: "#fff" }),
        stroke: new olStyleStroke({
          color: "#000",
          width: 5,
        }),
        textAlign: "left",
        offsetX: iconDiameter / 2,
        offsetY: iconDiameter / 2,
        text: text,
      }),
    }),
  ];

  return singularStyleCache[tag];
}

function getStyleCacheTag(videoSoft, text) {
  return videoSoft.getId();
}

const clusterStylesCache = {};

/**
 * returns the style for showing clustered features
 * @param {Array} features list of features in the cluster
 * @return {Object} cluster style
 */
function getClusterStyle(features, videoSoft) {
  var size = features.length;

  if (clusterStylesCache[size]) return clusterStylesCache[size];

  const icon = getClusterIcon(videoSoft);
  const iconOptions = _.assign(
    {
      rotateWithView: false,
      src: icon,
      scale: 2,
    },
    defaultIconOptions
  );

  const style1 = new olStyleStyle({
    image: new olStyleIcon(iconOptions),
  });

  const style2 = new olStyleStyle({
    text: new olStyleText({
      font: "15px Calibri,sans-serif",
      text: size.toString(),
      fill: new olStyleFill({
        color: "#fff",
      }),
      offsetX: -4,
    }),
  });

  clusterStylesCache[size] = _.concat(style2, style1);

  return clusterStylesCache[size];
}

function getIconText(videoSoft) {
  return videoSoft.values_.title || "NO TITLE";
}

function getIcon(videoSoft) {
  let imageSrc = null;

  imageSrc = "data:image/svg+xml;base64," + videoIconBase64;

  return imageSrc;
}

function getClusterIcon() {
  let imageSrc = null;

  imageSrc = "data:image/svg+xml;base64," + videoClusterIconBase64;

  return imageSrc;
}

export default new VideoSoftOverlay(defaultOptions);

import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  username: baseSchema(Language).requiredString,
  password: baseSchema(Language).requiredStringMinMax,
  server: baseSchema(Language).url
});

export default schema;

import { default as Module } from "sccModule";
var Utils = require("sccUtils").default;
var _ = require("lodash");

var showIridium = "NO";

/**
 * The base object for Permission module
 *
 */
class PermissionModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "permission",
      getterSetter: ["currentUserId"], //define both a getter and setter for the currentUserId (an int, which is the id of the currently logged in user)
    };
    super(options);

    // stores all the roles/permissions
    this._allRoles = null;

    // stores all available permissions grouped by module name
    this._allPermissions = null;

    // stores all available permissions
    this._allPermissionsByModule = null;

    // stores role data with permissions stored grouped by module name
    this._dataByModule = null;
  }
}

/**
 * verifies whether or not user has permission to view given module/actions
 *
 * @method verify
 * @memberof PermissionModule
 *
 * @param {String} module - module name
 * @param {Array} actions - an array of action names
 * @return {Boolean} true if requested permission is satisfied, and false otherwise
 */
PermissionModule.prototype.verify = function (module, actions, noModuleCheck) {
  if (!this.initialized) return false;

  if (module === "Maps" && actions !== "delete") {
    module = "client";
  }

  actions = !actions ? [] : _.concat([], actions);

  var userPermissions = this.getDataByModule("permissions");

  var userActions = _.map(userPermissions[module], "action");

  // checks whether or not the corresponding module is available to the client
  var moduleAvailable = noModuleCheck || this.isModuleAvailable(module);

  // user have access to everything in the module
  var allPermitted = _.indexOf(userActions, "all") > -1;

  // userActions is not empty and user have access to each requested action
  var actionsPermitted =
    userActions.length &&
    _.intersection(userActions, actions).length === actions.length;

  // this is a temporary hack to be able to verify whether or not container module is
  // availabe to the user. This should be removed when the features and permissions are
  // merged or their list of modules match
  if (module === "container") actionsPermitted = true;

  // should have access to all actions or to each action individually
  return moduleAvailable && (allPermitted || actionsPermitted);
};

/**
 * Wrapper on the verify function to allow environment level disabling of Irridium
 *
 * @method verifyIrridium
 * @memberof PermissionModule
 *
 * @param {String} module - module name
 * @param {Array} actions - an array of action names
 * @return {Boolean} true if requested permission is satisfied, and false otherwise
 */
PermissionModule.prototype.verifyIrridium = function (
  module,
  actions,
  noModuleCheck
) {
  // Show only if iridium is manually enabled for this system.
  return (
    this.verify(module, actions, noModuleCheck) &&
    showIridium === "IRIDIUM_BILLING_ENABLED"
  );
};

/**
 * verifies whether or not requested module is available to the client
 *
 * @method isModuleAvailable
 * @memberof PermissionModule
 *
 * @param {String} moduleName - module name
 * @return {Boolean} true if requested module is available or does not require checking, and false otherwise
 */
PermissionModule.prototype.isModuleAvailable = function (moduleName) {
  // this holds the list of features that are assigned to clients in the admin section.
  // TODO: change the features list to match modules in the list of permissions
  // and remove the extra step to check whether the module name is available in admin
  var allModules = [
    "device",
    "poi",
    "geofence",
    "sa",
    "nr",
    "eqnx",
    "ar",
    "container",
    "message",
    "sync",
    "alerts",
    "address_book",
    "hermes_gateways",
  ];
  if (_.indexOf(allModules, moduleName) > -1) {
    var availableModules = this.get("features");
    return _.indexOf(availableModules, moduleName) > -1;
  }

  return true;
};

/**
 * generates an object from the list of all permissions grouped by module names.
 * used for showing available permissions for users
 *
 * @method getGroupedPermissions
 * @memberof PermissionModule
 *
 * @param {Array} permissions - an array of permissions
 * @return {Object} permission object grouped by module name
 */
PermissionModule.prototype.getGroupedPermissions = function (permissions) {
  var $this = this;

  var permsObj = _.map(permissions, function (permId) {
    return $this.getAllPermissions(permId);
  });

  return _.groupBy(permsObj, function (perm) {
    return perm?.module;
  });
};

/**
 * sets the grouped permission object when the global object conatining all
 * roles is modified
 *
 * @method setGroupedPermissions
 * @memberof PermissionModule
 *
 * @param {Array} permissions - an array of permissions
 * @return {Object} permission object grouped by module name
 */
PermissionModule.prototype.setGroupedPermissions = function (value, keys) {
  if (value == null) return;

  var $this = this;
  if (keys && keys.length > 0) {
    var id = keys[0];
    this._allRoles[id].groupedPermissions = this.getGroupedPermissions(
      this._allRoles[id].permissions
    );
  } else {
    // add a new object to each role containing the permissions of the role grouped by module
    _.each(this._allRoles, function (role) {
      role.groupedPermissions = $this.getGroupedPermissions(role.permissions);
    });
  }
};

/**
 * reorders the action list by a given array
 *
 * @method reorderByAction
 * @memberof PermissionModule
 *
 * @param {Array} an array of permission objects
 * @return {Array} the reordered array of permission objects
 */
PermissionModule.prototype.reorderByAction = function (permissions) {
  var index = 0;
  var sortKeys = ["all", "view", "add", "edit", "delete"];
  _.each(sortKeys, function (key) {
    var keyIndex = _.findIndex(permissions, function (perm) {
      return perm.action === key;
    });
    if (permissions.length > 1 && keyIndex > -1) {
      var temp = permissions[index];
      permissions[index] = permissions[keyIndex];
      permissions[keyIndex] = temp;
      index++;
    }
  });
  return permissions;
};

/**
 * checks whether or a given permission item has
 *
 * @method verify
 * @memberof PermissionModule
 *
 * @param {String} module name
 * @param {Array} an array of action names
 * @return {Boolean} true if requested permission is satisfied, and false otherwise
 */
PermissionModule.prototype.checkPerm = function (permissions, module, action) {
  if (!permissions || !permissions[module]) return false;

  return _.reduce(
    permissions[module],
    function (result, perm) {
      return result || action === perm.action;
    },
    false
  );
};

/**
 * gets all roles/permission of the client or value of a specific key
 *
 * @method getAllRoles
 * @memberof PermissionModule
 * @param {Integer|Array} keys - an array of keys to be used to get to the requested value recursively
 * @return {object} roles/permission object
 */
PermissionModule.prototype.getAllRoles = function (keys) {
  return this.getData(this._allRoles, keys);
};

/**
 * sets all roles/permission of the client or value of a specific key
 *
 * @method setAllRoles
 * @memberof PermissionModule
 * @param {Any} the value to be set at the requested key
 * @param {Integer|Array} keys - an array of keys to be used to get to the requested value recursively
 * @param {Boolean} merge - whether or not to merge the value with current object
 * @return {object} roles/permission object
 */
PermissionModule.prototype.setAllRoles = function (value, keys, merge) {
  this._allRoles = this.setData(this._allRoles, value, keys, merge);
  this.setGroupedPermissions(value, keys);

  return this._allRoles;
};

/**
 * gets all available permissions or value of a specific key
 *
 * @method getAllPermissions
 * @memberof PermissionModule
 * @param {Integer|Array} keys - an array of keys to be used to get to the requested value recursively
 * @return {object} an object of all available permissions
 */
PermissionModule.prototype.getAllPermissions = function (keys) {
  return this.getData(this._allPermissions, keys);
};

/**
 * gets all available permissions or value of a specific key
 * from permission object grouped by module name
 *
 * @method getAllPermissions
 * @memberof PermissionModule
 * @param {Integer|Array} keys - an array of keys to be used to get to the requested value recursively
 * @return {object} an object of all available permissions
 */
PermissionModule.prototype.getAllPermissionsByModule = function (keys) {
  return this.getData(this._allPermissionsByModule, keys);
};

/**
 * sets all available permissions or value of a specific key
 *
 * @method setAllPermissions
 * @memberof PermissionModule
 * @param {Any} the value to be set at the requested key
 * @param {Integer|Array} keys - an array of keys to be used to get to the requested value recursively
 * @param {Boolean} merge - whether or not to merge the value with current object
 * @return {object} an object of all available permissions
 */
PermissionModule.prototype.setAllPermissions = function (value, keys, merge) {
  this._allPermissions = this.setData(this._allPermissions, value, keys, merge);

  this._allPermissionsByModule = _.groupBy(
    this._allPermissions,
    function (perm) {
      return perm.module;
    }
  );

  return this._allPermissions;
};

/**
 * loads all available permissions (module/action relation) from the database
 *
 * @method loadAllPermissions
 * @memberof PermissionModule
 * @return {object} an object of all available Permissions in a promise
 */
PermissionModule.prototype.loadAllPermissions = function () {
  var $this = this;
  var options = {
    url: this.routeUrl + "/data",
    method: "GET",
  };
  return Utils.httpRequestHandler(options).then(function (response) {
    return Promise.resolve($this.setAllPermissions(response.data.result));
  });
};

/**
 * loads all the roles/permissions from the database
 *
 * @method loadAllRoles
 * @memberof PermissionModule
 * @return {object} Roles/Permissions data in a promise
 */
PermissionModule.prototype.loadAllRoles = function () {
  var $this = this;
  var options = {
    url: this.routeUrl + "/all",
    method: "GET",
  };
  return Utils.httpRequestHandler(options).then(function (response) {
    showIridium = response.data.showIridium;
    return $this.setAllRoles(response.data.result);
  });
};

/**
 * gets the display name for a given module
 *
 * @method getModuleName
 * @memberof PermissionModule
 *
 * @param {String} abbr module's stored name
 * @return {String} module's display name
 */
PermissionModule.prototype.getModuleName = function (abbr) {
  var dict = {
    admin_user: "Admin User",
    feed: "Feed",
    sync: "Sync",
    admin_device: "Admin Device",
    client: "Clients",
    user: "Users",
    device: "Assets",
    group: "Groups",
    geofence: "Geofences",
    poi: "Points of Interest",
    nr: "Nearest Responders",
    ar: "Alert Rules",
    sa: "Situational Awareness",
    eqnx: "Equinox",
    history: "History Playback",
    report: "Reports",
    message: "Text Messaging",
    alarm: "Alarms",
    alerts: "Alerts",
    iridium_billing: "Iridium Billing",
    address_book: "Address Book",
    hermes_gateways: "Hermes Gateways",
    tak: 'Tak'
  };

  if (!(abbr in dict)) {
    throw new Error(abbr + " is not a recognized module name.");
  }

  return dict[abbr];
};

/**
 * gets the display name for a given action
 *
 * @method getActionName
 * @memberof PermissionModule
 *
 * @param {String} abbr action's stored name
 * @return {String} action's display name
 */
PermissionModule.prototype.getActionName = function (action) {
  var dict = {
    all: "All Actions",
    view: "View",
    edit: "Edit",
    delete: "Delete",
    add: "Add",
    poll: "Polling",
    setcode: "Settings Code",
    setaes: "Manage AES",
    reset: "Reset",
    addsibling: "Roles-Permissions",
    monitor: "Monitor",
  };

  if (!(action in dict)) {
    throw new Error(action + " is not a recognized action value.");
  }

  return dict[action];
};

/**
 * overrides the default onSocketDelete method from Module class
 */
PermissionModule.prototype.onSocketDelete = function (url, data) {
  if (!data.id) throw new Error("Socket received data must have 'id'.");

  this.setAllRoles(null, data.id);
};

/**
 * overrides the default onSocketUpdate method from Module class
 */
PermissionModule.prototype.onSocketUpdate = function (url, data) {
  if (!data.id) throw new Error("Socket received data must have 'id'.");
  if (this.getAllRoles()) {
    this.setAllRoles(data, data.id, false);
  }

  if (parseInt(Utils.getCookie("userrole")) === parseInt(data.id)) {
    var btn = document.getElementById("reloadHelper");
    btn.click();
  }

  if (this.get("id") === data.id) {
    this.set(data.permissions, "permissions");
    this.set(data.title, "title");
  }
};

/**
 * overrides the default onSocketAdd method from Module class
 */
PermissionModule.prototype.onSocketAdd = function (url, data) {
  if (!data.id) throw new Error("Socket received data must have 'id'.");

  this.setAllRoles(data, data.id);
};

/**
 * overrides the default set method from Module class
 */
PermissionModule.prototype.set = function (value, keys, merge) {
  var data = Module.Module.prototype.set.call(this, value, keys, merge);

  // building the object that holds permission data grouped by module name
  this._dataByModule = this.buildDataByModule();

  // set _allRoles, if only one item, manupulate the data to object with Id as key
  if (this._dataByModule.title) {
    const manipulatedData = {};
    manipulatedData[this._dataByModule.id] = this._dataByModule;

    this._allRoles = manipulatedData;
  } else {
    this._allRoles = this._dataByModule;
  }

  return data;
};

/**
 * builds the user permission data object with permissions grouped by module name
 */
PermissionModule.prototype.buildDataByModule = function () {
  var dataByModule = _.cloneDeep(this.get());
  dataByModule.permissions = this.getGroupedPermissions(
    dataByModule.permissions
  );

  return dataByModule;
};

/**
 * gets the user permission data stored grouped by module name
 */
PermissionModule.prototype.getDataByModule = function (keys) {
  return this.getData(this._dataByModule, keys);
};

PermissionModule.prototype.loadData = function () {
  var $this = this;
  return this.loadAllPermissions().then(function (permissions) {
    return Module.Module.prototype.loadData.call($this);
  });
};

export default new PermissionModule();

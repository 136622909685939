import React, { useContext } from "react";

// Internal

import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";
import VideoSoftDataDisplay from "@videoSoft/components/DataDisplay/videoSoftDataDisplay";

// Material-UI
import Paper from "@material-ui/core/Paper";

export default function DataDisplay() {
  const { videoSofts } = useContext(VideoSoftContext);

  const hasPopUpToDisplay = videoSofts?.length > 0;

  return (
    <Paper
      elevation={3}
      id={"olMapVideoSoftPopup"}
      style={{
        display: hasPopUpToDisplay ? "flex" : "none",
        width: "600px",
        // height: "300px",
      }}
    >
      <VideoSoftDataDisplay videoSofts={videoSofts} />
    </Paper>
  );
}

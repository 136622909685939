import React, { useContext, useState, useEffect } from "react";

// Material-UI
import {
  makeStyles,
  ListItem,
  List,
  IconButton,
  Collapse,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import PlayCircleFilledWhiteRoundedIcon from "@material-ui/icons/PlayCircleFilledWhiteRounded";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import EditIcon from "@material-ui/icons/Edit";

// Components
import CustomTooltip from "@Components/CustomTooltip";
import CustomDialogYesNo from "@Components/CustomDialogYesNo/CustomDialogYesNo";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import olMap from "sccOlMapNew";
import Language from "sccLanguage";
import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";
import VideoSoftInfo from "@videoSoft/components/DataDisplay/VideoSoftInfo/index.js";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  root: {
    display: "grid",
    margin: "10px",
    overflow: "auto",
  },
  listItem: {
    backgroundColor: "#eeeeee",
  },
  iconSize: {
    fontSize: "20px",
  },
  refreshIcon: {
    position: "absolute",
    top: 10,
    paddingLeft: 6,
  },
  zoomToCoord: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      width: 12,
      height: 12,
      paddingLeft: 4,
    },
  },
  dataContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
    marginBottom: "8px",
    height: "165px",
    width: "100%",
    overflow: "auto",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alighItems: "center",
  },
}));

const filterObject = [
  { id: 1, name: "ALL" },
  { id: 2, name: "NO GPS" },
  { id: 3, name: "WITH GPS" },
];

export default function VideoSoftList(props) {
  const classes = useStyles();
  const { videoSofts, videoSoftLogout, goToVideoSoftForm } = useContext(VideoSoftContext);
  const [expanded, setExpanded] = useState({});
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [filteredVs, setFilterVs] = useState(videoSofts);
  const [filterValue, setFilterValue] = useState(filterObject[0].id);


  const handleLogOut = () => {
    videoSoftLogout();
  };

  const onFilterChange = (e) => {
    e.preventDefault();

    setFilterValue(e.target.value);
  };

  useEffect(() => {
    switch (filterValue) {
      case filterObject[0].id:
        setFilterVs(videoSofts);

        break;

      case filterObject[1].id:
        const filtered1 = videoSofts.filter((vs) => !vs.latitude);

        setFilterVs(filtered1);

        break;

      case filterObject[2].id:
        const filtered2 = videoSofts.filter((vs) => vs.latitude);

        setFilterVs(filtered2);

        break;

      default:
        setFilterVs(videoSofts);
        break;
    }
  }, [filterValue, videoSofts]);

  const zoomToCoord = (longitude, latitude) => {
    const zoom = olMap.mapQuickZoomInLevel;
    const orgCoord = [longitude, latitude];
    const coord = olMap.transformToMapCoordinate(orgCoord);
    olMap.setCenterAndZoom(coord, zoom);
  };

  const expandedHandle = (index) => {
    setExpanded((openState) => ({
      ...openState,
      [index]: !openState[index],
    }));
  };

  return (
    <React.Fragment>
      <CustomDialogYesNo
        text={Language.translate("Do you want to logout videoSoft")}
        open={logoutDialogOpen}
        onNoAction={() => {
          setLogoutDialogOpen(false);
        }}
        onYesAction={() => {
          setLogoutDialogOpen(false);
          handleLogOut();
        }}
      />
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <div style={{ justifySelf: "start" }}>
            <Select
              id="filter"
              value={filterValue}
              label={"Filter"}
              onChange={(e) => onFilterChange(e)}
            >
              {filterObject.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ justifySelf: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => setLogoutDialogOpen(true)}
            >
              {Language.translate("Log Out")}
            </Button>
          </div>
        </div>
        <List>
          {filteredVs?.length > 0 &&
            filteredVs.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  className={classes.listItem}
                  key={index}
                  onClick={() => {
                    expandedHandle(index);
                  }}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <CustomTooltip title={item.title} placement="left">
                      <span>
                        <PlayCircleFilledWhiteRoundedIcon
                          className={classes.iconSize}
                        />
                      </span>
                    </CustomTooltip>
                  </ListItemIcon>

                  <ListItemText primary={item.title} />
                  <ListItemSecondaryAction
                    className={classes.itemActionButtonsContainer}
                  >
                    <CustomTooltip
                      title={Language.translate("Locate")}
                      placement="top"
                    >
                      {item.longitude && item.latitude ? (
                        <IconButton
                          className="zoomToCoord"
                          edge="end"
                          aria-label="locate"
                          onClick={() => {
                            zoomToCoord(item.longitude, item.latitude);
                          }}
                        >
                          <MyLocationIcon />
                        </IconButton>
                      ) : null}
                    </CustomTooltip>
                    <CustomTooltip
                      title={Language.translate("Edit")}
                      placement="top"
                    >
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => goToVideoSoftForm(item.title, item.machineid, item.reportInterval)}
                      >
                        <EditIcon />
                      </IconButton>
                    </CustomTooltip>
                  </ListItemSecondaryAction>
                </ListItem>

                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <div className={classes.dataContainer}>
                    <VideoSoftInfo gateway={item} />
                  </div>
                </Collapse>
              </React.Fragment>
            ))}
        </List>
      </div>
    </React.Fragment>
  );
}

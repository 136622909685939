import React, { useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { IconButton } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import EditIcon from "@material-ui/icons/Edit";

//style
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Language from "sccLanguage";
import CustomTooltip from "@Components/CustomTooltip";
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import Permission from "sccPermission";
import VideoSoft from "@videoSoft/scripts/VideoSoft";
import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  container: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.colors.gray.main,
  },

  clusterContiner: {
    height: 26,
    padding: "2px",
    display: "flex",
    border: "1px solid #ddd",
    alignItems: "center",
  },

  zoomButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.colors.gray.dark,
    width: 20,
    height: 26,
    marginLeft: 3,
    "&:hover": {
      borderRadius: 0,
      backgroundColor: theme.palette.colors.gray.dark,
      "& $deviceDisplayIcon": {
        color: theme.palette.colors.black.main,
      },
    },
  },

  zoomIcon: {
    color: "#293239",
    color: alpha(theme.palette.colors.black.main, 0.85),
    width: 16,
    height: 16,
  },
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

const videoSoftObject = {
  tab: "videoSoft",
  shortTitle: "video",
  title: "VideoSoft",
  moduleName: "videoSoft",
  module: VideoSoft,
  imageClass: "play",
  extLink: false,
  isList: true,
  wrapperDisplayMode: MW_LIST_MODE,
  showFilter: false,
  showSearch: false,
  showFooter: true,
  verifyShownPermission: Permission.verify("device", "view"),
  mapLayer: VideoSoftOverlay,
};

export default function DataDisplayButtonGroup({ gateway }) {
  const [mwState] = useContext(ModuleWrapperContext);
  const { goToVideoSoftForm } = useContext(VideoSoftContext);

  const handleZoom = () => {
    const id = VideoSoftOverlay.getSelectedId();
    VideoSoftOverlay.centerAndZoomTo(id);
  };

  const handleEdit = () => {
    mwState.openModuleWrapperPopper(videoSoftObject);

    goToVideoSoftForm(gateway.title, gateway.machineid, gateway.reportInterval);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.clusterContiner}>
        <CustomTooltip
          title={Language.translate("Zoom In")}
          arrow
          placement="top"
        >
          <IconButton
            className={classes.zoomButton}
            onClick={(e) => handleZoom(e)}
          >
            <GpsFixedIcon className={classes.zoomIcon} />
          </IconButton>
        </CustomTooltip>
        <CustomTooltip title={Language.translate("Edit")} placement="top">
          <IconButton
            className={classes.zoomButton}
            edge="end"
            aria-label="edit"
            onClick={handleEdit}
          >
            <EditIcon />
          </IconButton>
        </CustomTooltip>
      </div>
    </div>
  );
}

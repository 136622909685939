const olLayerTile = require("ol/layer/Tile").default;
const olSourceOsm = require("ol/source/OSM").default;
const olSourceXYZ = require("ol/source/XYZ").default;
const MapboxVector = require("ol/layer/MapboxVector").default;
const _ = require("lodash");

const log = require("loglevel");

class OlMapBaseLayer {
  constructor(options) {
    this.map = options.map;
    this.baseLayers = options.baseLayers;
    this.settingDefaultMapAsOL = false;
  }

  init() {
    const UserSetting = require("sccUserSetting").default;
    const mapLayerVisibility = UserSetting.get("map_layers");
    const olMap = require("sccOlMapNew").default;

    if (
      mapLayerVisibility["Google Physical"] == true ||
      mapLayerVisibility["Google Satellite"] == true ||
      mapLayerVisibility["Google Hybrid"] == true ||
      mapLayerVisibility["Google Street"] == true
    ) {
      olMap.currentMapsAPI = "googleMaps";
    } else olMap.currentMapsAPI = "OlMaps";

    console.log("initting olmapbaselayer");
  }

  add(options) {
    let layer;
    const UserSetting = require("sccUserSetting").default;
    const mapLayerVisibility = UserSetting.get("map_layers");
    switch (options.code) {
      case "gphy":
        layer = new olLayerTile({
          title: "Google Physical",
          type: "base",
          visible: mapLayerVisibility["Google Physical"],
        });
        break;
      case "gsat":
        layer = new olLayerTile({
          title: "Google Satellite",
          type: "base",
          visible: mapLayerVisibility["Google Satellite"],
        });
        break;
      case "ghyb":
        layer = new olLayerTile({
          title: "Google Hybrid",
          type: "base",
          visible: mapLayerVisibility["Google Hybrid"],
        });
        break;
      case "gmap":
        layer = new olLayerTile({
          title: "Google Street",
          type: "base",
          visible: mapLayerVisibility["Google Street"],
        });
        break;
      case "osm":
        layer = new olLayerTile({
          title: "OSM",
          type: "base",
          visible: mapLayerVisibility["OSM"],
          source: new olSourceOsm({
            wrapX: false,
          }),
        });
        break;
      case "custom_xyz":
        layer = new olLayerTile({
          title: options.name,
          type: "base",
          visible:
            mapLayerVisibility[options.name] != null
              ? mapLayerVisibility[options.name]
              : false,
          source: new olSourceXYZ({
            wrapX: false,
            url: options.url,
          }),
        });
        break;
      case "mapbxsat":
        layer = new olLayerTile({
          title: "Mapbox Satellite",
          type: "base",
          visible: mapLayerVisibility["Mapbox Satellite"],
          source: new olSourceXYZ({
            url:
              "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=" +
              getMapboxToken(),
            attributions: "Mapbox",
          }),
        });
        break;
      case "mapbxstr":
        layer = new MapboxVector({
          visible: mapLayerVisibility["Mapbox Street"],
          title: "Mapbox Street",
          type: "base",
          styleUrl: "mapbox://styles/mapbox/streets-v11",
          accessToken: getMapboxToken(),
        });
        // Hacking the title in forcibly, since it won't take nicely.
        layer.values_.title = "Mapbox Street";
        break;

      default:
        log.warn("Wrong map layer code provided:", options.code);
        return;
    }

    layer.on("change:visible", changeLayerHandler, this.map);
    const baseLayers = this.baseLayers.getLayers();
    baseLayers.push(layer);
  }

  toggleLayerVisibility(layer, layerGroup) {
    let visibility = true;
    _.each(layerGroup.getLayers().getArray(), (lyr) => {
      lyr.setVisible(false);
    });
    layer.setVisible(visibility);
  }

  setDefaultMap() {
    //Loops through the array of BaseLayers/maps to display, sets a default map to display if necessary
    let isVisible = false;
    const baseLayers = this.baseLayers.getLayers();
    const baseLayer = window.olMap.map.getLayers().getArray()[0];
    for (let i = 0; i < baseLayers.getArray().length; i++) {
      if (baseLayers.getArray()[i].values_.visible) {
        //found instance that has visible: true field
        if (
          baseLayers.getArray()[i].values_.title.includes("Google") &&
          !window.invalidGoogleMapKey &&
          !window.initializingAlertMap &&
          window.googleMapsAPIloaded
        ) {
          //alert map default map setup handled in sccGoogleMap module
          this.toggleLayerVisibility(baseLayers.getArray()[i], baseLayer);
        } else if (baseLayers.getArray()[i].values_.title.includes("Google")) {
          //invalid license or no response from Google Maps API
          isVisible = false; //the "if (!isVisible)" block following this for loop is executed
          break;
        } else {
          window.olMap.BaseLayer.settingDefaultMapAsOL = true;
          this.toggleLayerVisibility(baseLayers.getArray()[i], baseLayer);
        }
        isVisible = true;
        break;
      }
    }

    if (!isVisible) {
      //no instances contain visible:true field
      var anotherLayerFound = false;
      for (let i = 0; i < baseLayers.getArray().length; i++) {
        if (
          baseLayers.getArray()[i].values_.title.includes("Google") &&
          !window.invalidGoogleMapKey &&
          !window.initializingAlertMap &&
          window.googleMapsAPIloaded
        ) {
          this.toggleLayerVisibility(baseLayers.getArray()[i], baseLayer);
          anotherLayerFound = true;
          break;
        } else if (!baseLayers.getArray()[i].values_.title.includes("Google")) {
          window.olMap.BaseLayer.settingDefaultMapAsOL = true;
          this.toggleLayerVisibility(baseLayers.getArray()[i], baseLayer);
          anotherLayerFound = true;
          break;
        }
      }
      if (!anotherLayerFound) {
        //no ol layer found, user is given access to OSM layer which is also made visible
        window.olMap.BaseLayer.settingDefaultMapAsOL = true;
        this.giveOSMlayerAccessAndMakeVisible();
      }
    }
  }

  giveOSMlayerAccessAndMakeVisible() {
    //called if google maps API authentication failed; adds osm layer and makes it visible
    var osmLayerFound = false;
    const baseLayers = this.baseLayers.getLayers();
    const baseLayer = window.olMap.map.getLayers().getArray()[0];

    for (let i = 0; i < baseLayers.getArray().length; i++) {
      if (baseLayers.getArray()[i].values_.title == "OSM") {
        osmLayerFound = true;
        this.toggleLayerVisibility(baseLayers.getArray()[i], baseLayer);
      } else {
        baseLayers.getArray()[i].setVisible(false);
      }
    }
    if (!osmLayerFound) {
      log.debug("OSM layer not found. Giving access to OSM layer..");
      this.add({ code: "osm", name: "OSM" });
      this.giveOSMlayerAccessAndMakeVisible();
    }
  }
}

function changeLayerHandler(event) {
  const UserSetting = require("sccUserSetting").default;
  var mapLayerVisibility = UserSetting.get("map_layers");
  const visStatusObj = event.target.values_;
  const layerName = visStatusObj.title;
  const isVisible = visStatusObj.visible;

  _.each(
    _.filter(
      _.keys(mapLayerVisibility),
      (layer) => !["POI", "Geofences", "Assets", "VideoSoft"].includes(layer)
    ),
    (layerName) => {
      mapLayerVisibility[layerName] = false;
    }
  );

  mapLayerVisibility[layerName] = isVisible;

  UserSetting.update({ map_layers: mapLayerVisibility }).then(function (data) {
    log.debug("Map Layer Visibility Updated", data);
  });

  if (window.name == "History") {
    setTimeout(() => {
      if (
        !(
          mapLayerVisibility["Google Physical"] == true ||
          mapLayerVisibility["Google Satellite"] == true ||
          mapLayerVisibility["Google Hybrid"] == true ||
          mapLayerVisibility["Google Street"] == true
        )
      ) {
        document.getElementById("mapAttrDisplay").style.visibility = "visible";
      }
    }, 300);
  }
  if (
    (mapLayerVisibility["Google Physical"] == true ||
      mapLayerVisibility["Google Satellite"] == true ||
      mapLayerVisibility["Google Hybrid"] == true ||
      mapLayerVisibility["Google Street"] == true) &&
    window.googleMapsAPIloaded
  ) {
    switchToGoogleMapsAPI(layerName);
  } else {
    switchToOlMapsAPI();
  }
}

function switchToGoogleMapsAPI(layerName) {
  const olMap = window.olMap;
  const googleMap = window.googleMap;

  olMap.currentMapsAPI = "googleMaps";

  googleMap.changeGoogleMapLayer(layerName);
  window.openlayersTilesOpacity = 0;

  if (layerName == "Google Hybrid" || layerName == "Google Satellite") {
    googleMap.checkForMaxZoom(olMap.getCenter(), olMap.getZoom()); //ensuring that zoom set to <=max zoom for satellite imagery at given map center
  }

  document.getElementById("mapW").style.background = "#b5d0d000"; //making fully transparent
  document.getElementById("googleMap").style.opacity = 1;

  if (
    window.name == "History" &&
    document.getElementsByClassName("BottomPanel active")[0]
  ) {
    _.each(
      document.getElementsByClassName("mapBottomLeftElements"),
      (element) => {
        element.style.bottom = "90px";
      }
    );
  } else {
    _.each(
      document.getElementsByClassName("mapBottomLeftElements"),
      (element) => {
        element.style.bottom = "20px";
      }
    );
  }

  document.getElementsByClassName("mapLayersBtn")[0].style.bottom = "15px";
  document.getElementsByClassName("mapSettingsBtn")[0].style.bottom = "15px";
  document.getElementById("olMapZoomButtons").style.bottom = "20px";
  document.getElementsByClassName("mapToolsBtn")[0].style.bottom = "15px";

  if (document.getElementById("mapAttrDisplay"))
    document.getElementById("mapAttrDisplay").style.visibility = "hidden";

  googleMap.setCenter(olMap.getCenter(), olMap.getZoom());
  googleMap.setZoom(olMap.getZoom());
}

function switchToOlMapsAPI() {
  const olMap = require("sccOlMapNew").default;
  const googleMap = require("sccGoogleMap");
  olMap.currentMapsAPI = "OlMaps";

  if (!window.invalidGoogleMapKey && window.googleMapsAPIloaded) {
    olMap.setCenterAndZoom(
      olMap.transformToMapCoordinate(googleMap.getCenter()),
      googleMap.getZoom()
    );
  }

  window.openlayersTilesOpacity = 1;
  document.getElementById("mapW").style.background = "#b5d0d0"; //removing transparency
  document.getElementById("googleMap").style.opacity = 0;

  if (
    window.name == "History" &&
    document.getElementsByClassName("BottomPanel active")[0]
  ) {
    //moves the bottom left elements to be
    //above the History controls bar

    _.each(
      document.getElementsByClassName("mapBottomLeftElements"),
      (element) => {
        element.style.bottom = "70px";
      }
    );
  } else {
    _.each(
      document.getElementsByClassName("mapBottomLeftElements"),
      (element) => {
        element.style.bottom = "0px";
      }
    );
  }

  document.getElementsByClassName("mapLayersBtn")[0].style.bottom = "0px";

  document.getElementsByClassName("mapSettingsBtn")[0].style.bottom = "0px";
  document.getElementsByClassName("mapToolsBtn")[0].style.bottom = "0px";
  document.getElementById("olMapZoomButtons").style.bottom = "5px";
  //document.getElementById("mapToolsPopoverBox").style.bottom="35px";

  if (document.getElementById("mapAttrDisplay"))
    document.getElementById("mapAttrDisplay").style.visibility = "visible";
  //document.getElementById("mapAttrDisplay").style.width=olMapAttrDisplayElementWidth;
}

function getMapboxToken() {
  const EnvVar = require("sccEnvVar").default;
  return EnvVar.get("MAPBOX_API_KEY");
}

export default OlMapBaseLayer;

import React, { Component } from "react";

// Material-UI
import Paper from "@material-ui/core/Paper";

// Internal
import ClusterDisplayButtonGroup from "@videoSoft/components/ClusterDisplay/ClusterDisplayButtonGroup";
import ClusterList from "@videoSoft/components/ClusterDisplay/ClusterList";
import ClusterDisplayHeader from "@videoSoft/components/ClusterDisplay/ClusterDisplayHeader";
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";

const useStyles = {
  tableContainer: {
    width: "100%",
    display: "grid",
    flexDirection: "column",
    border: "1px solid #ddd",
  },
};

export default class videoSoftClusterDisplay extends Component {
  constructor() {
    super();
    this.state = {
      gatewayIds: [],
    };

    if (!videoSoftClusterDisplay.publicInstance) {
      videoSoftClusterDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected(id) {
    setTimeout(() => {
      videoSoftClusterDisplay.publicInstance.setFeatureSelected(id);
    });
  }

  setFeatureSelected(id) {
    const selectedId = id || VideoSoftOverlay.getClusterFeatureIds();
    this.setState({ gatewayIds: selectedId });
  }

  render() {
    const { gatewayIds } = this.state;

    return (
      <Paper
        elevation={3}
        id={"olMapVideoSoftClusterPopup"}
        style={{
          display: gatewayIds?.length > 0 ? "flex" : "none",
        }}
      >
        <div style={useStyles.tableContainer}>
          <ClusterDisplayHeader />
          <ClusterDisplayButtonGroup gatewayIds={gatewayIds} />
          <ClusterList gatewayIds={gatewayIds} />
        </div>
      </Paper>
    );
  }
}

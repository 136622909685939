import React, { useContext, useEffect, useState } from "react";

// Material-UI
import {
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Divider from "@material-ui/core/Divider";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";
import CustomButton from "@Components/CustomButton";
import Language from "sccLanguage";
import { AppContext } from "../../../AppContext";
import CustomDialog from "@Components/CustomDialog/index.js";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  formContainer: {
    overflow: "auto",
    margin: "10px",
    padding: "8px",
  },
  cellLocation: {
    display: "flex",
    alignItems: "center",
  },
  selectOption: {
    marginLeft: "30px",
    marginTop: "5px",
    marginBottom: "6px",
    width: "170px",
    height: "30px",
  },
  selectTime: {
    padding: "0px",
    textAlign: "center",
  },
  saveBtnLocation: {
    marginTop: "10px",
  },
  saveBtn: {
    width: "95px",
    height: "35px",
  },
  backBtnLocation: {
    justifySelf: "start",
    padding: "5px",
    marginLeft: "10px",
  },
  backBtn: {
    width: "95px",
    height: "35px",
  },
  title: {
    marginBottom: "5px",
  },
}));

export default function VideoSoftForm() {
  const classes = useStyles();
  const [appState] = useContext(AppContext);
  const {
    videoSoftFormValue,
    setVideoSoftFormValue,
    setDisplayVidoeSoftForm,
    setVideoSofts,
    vsFormOriginal,
    setVsFormOriginal,
    vsUsername
  } = useContext(VideoSoftContext);

  const reportIntervalTime = [
    { value: 5000, timeString: "5 Second" },
    { value: 10000, timeString: "10 Second" },
    { value: 15000, timeString: "15 Second" },
    { value: 30000, timeString: "30 Second" },
    { value: 60000, timeString: "1 Minute" },
    { value: 300000, timeString: "5 Minute" },
    { value: 600000, timeString: "10 Minute" },
    { value: 900000, timeString: "15 Minute" },
    { value: 1800000, timeString: "30 Minute" },
    { value: 3600000, timeString: "1 Hour" },
  ];


  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeReportInterval = (event) => {
    const value = event.target.value;
    setVideoSoftFormValue({ ...videoSoftFormValue, reportInterval: value });
  };

  let updateUrl = "/api/vs/update";

  if (process.env.REACT_APP_IS_INDEPENDENT_DEV) {
    updateUrl = "http://localhost:5000" + updateUrl;
  }

  function saveInterval() {
    const values = videoSoftFormValue;

    fetch(updateUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        machineid: values.machineid,
        reportInterval: values.reportInterval,
        user: vsUsername,
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        setVideoSofts((previouReportInterval) => {
          return previouReportInterval.map((data) => {
            if (values.machineid === data.machineid) {
              return {
                ...data,
                reportInterval: values.reportInterval,
                title: values.title,
              };
            } else {
              return data;
            }
          });
        });
        setVsFormOriginal(videoSoftFormValue);

        const message = {
          title: "VideoSoft",
          text: Language.translate("New Report Interval Save Success"),
          subText: "",
        };

        appState.displaySnackbarMessage({
          title: message.title,
          message: message.text,
          subMessage: message.subText,
          variant: "success",
        });
      })
      .catch((err) => {
        console.log("err", err);

        const message = {
          title: "VideoSoft",
          text: Language.translate("Error Save New Report Interval"),
          subText: "",
        };

        appState.displaySnackbarMessage({
          title: message.title,
          message: message.text,
          subMessage: message.subText,
          variant: "error",
        });

        return false;
      });
  }

  const handleBackToPage = () => {
    if (vsFormOriginal?.reportInterval !== videoSoftFormValue?.reportInterval) {
      setOpenDialog(true);
    } else {
      setVideoSoftFormValue({});
      setVsFormOriginal({});
      setDisplayVidoeSoftForm(false);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.backBtnLocation}>
        <CustomButton
          id="btnBack"
          size="medium"
          color="back"
          variant="contained"
          className={classes.backBtn}
          onClick={handleBackToPage}
          startIcon={<ArrowBackIosIcon />}
        >
          {Language.translate("Back")}
        </CustomButton>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.title}>
          <Typography variant="h6">{videoSoftFormValue.title}</Typography>
        </div>
        <Divider />
        <div className={classes.cellLocation}>
          <Typography variant="body1">
            {Language.translate("Report Interval")}
          </Typography>
          <FormControl className={classes.selectOption}>
            <Select
              id="time"
              value={videoSoftFormValue.reportInterval}
              onChange={handleChangeReportInterval}
              className={classes.selectTime}
            >
              {reportIntervalTime.map((reportInterval) => (
                <MenuItem
                  key={reportInterval.value}
                  value={reportInterval.value}
                >
                  {reportInterval.timeString}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Divider />
        <div className={classes.saveBtnLocation}>
          <Button
            variant="contained"
            className={classes.saveBtn}
            color="primary"
            onClick={saveInterval}
            disabled={
              vsFormOriginal?.reportInterval ===
              videoSoftFormValue?.reportInterval
            }
          >
            {Language.translate("SAVE")}
          </Button>
        </div>
      </div>
      <CustomDialog
        open={openDialog}
        onCancelAction={() => setOpenDialog(false)}
        onOkAction={() => {
          setVideoSoftFormValue({});
          setVsFormOriginal({});
          setDisplayVidoeSoftForm(false);
        }}
        text={Language.translate(
          "Your current changes will be lost. Do you want to continue?"
        )}
      />
    </React.Fragment>
  );
}

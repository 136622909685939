import React, { useContext } from "react";

// Material-UI
import { Typography, Button, Paper, makeStyles } from "@material-ui/core";

// Internal
import OlMap from "sccOlMapNew";
import Permission from "sccPermission";
import Language from "sccLanguage";
import MapRightClickMenu from "../MapRightClickMenu";
import MapUtilities from "../MapUtilities";
import MapZoomButtons from "../MapZoomButtons";
import { default as DeviceDataDisplay } from "../../../device/components/DataDisplay";
import HistoryTrailDataDisplay from "../../../device/components/HistoryTrailDataDisplay";
import { default as DeviceClusterDisplay } from "../../../device/components/ClusterDisplay";
import { default as PoiDataDisplay } from "../../../poi/components/DataDisplay";
import { default as PoiClusterDisplay } from "../../../poi/components/ClusterDisplay";
import { default as GeofenceDataDisplay } from "../../../geofence/components/DataDisplay";
import { default as ChatOverlay } from "../../../chat/components/ChatOverlay";
import { default as VideoSoftDataDisplay } from "../../../video_soft/components/DataDisplay";
import { default as VideoSoftClusterDisplay } from "../../../video_soft/components/ClusterDisplay";
import "./Map.scss";
import { HistoryContext } from "../../../history/components/HistoryContext";
import { SelectedGeofenceContext } from "../../../geofence/components/context/SelectedGeofenceContext";
import alertSound from "../../../alert/audio/alert.mp3";

const useStyles = makeStyles((theme) => ({
  map: {
    background: "#b5d0d0",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 4,
    transition: "0.2s",
    opacity: 1,
  },

  reshapeMap: {
    background: "#b5d0d0",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 4,
    opacity: 0.7,
    cursor: "copy",
    transition: "0.2s",
  },

  dragMap: {
    background: "#b5d0d0",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 4,
    transition: "0.2s",
    opacity: 0.7,
    cursor: "move",
  },

  histroyControlMap: {
    background: "#b5d0d0",
    width: "100%",
    height: "calc(100% - 70px)",
    position: "fixed",
    bottom: 70,
    left: 0,
    zIndex: 4,
    transition: "0.2s",
    opacity: 1,
  },
}));

export default function Map(props) {
  const [state] = useContext(HistoryContext);
  const { geoState } = useContext(SelectedGeofenceContext);
  const showHistoryControls = state.showHistoryControls;
  const classes = useStyles();

  // this function is used to play radio sound after user has clicked the button to permit Browser sound
  // google reference: https://developer.chrome.com/blog/autoplay/
  const handleSoundClick = () => {
    const audio = document.getElementById("emergencyAlertSound");
    audio.play();
    // close prompt popup
    document.getElementById("audioConfirmOverLay").style.display = "none";
    document.getElementById("audioConfirmBox").style.display = "none";
  };

  const editMode = geoState.moduleEditMode;
  const handleMapClass = () => {
    switch (editMode) {
      case "reshapeMap":
      case "start":
        return classes.reshapeMap;
      case "dragMap":
        return classes.dragMap;
      default:
        return classes.map;
    }
  };

  return (
    <React.Fragment>
      {!Permission.verify("client", "view") && (
        <>
          <div
            id={OlMap.getOptions("mapDiv")}
            // id="mapW"
            className={
              showHistoryControls ? classes.histroyControlMap : handleMapClass()
            }
          >
            {/* <!-- Audio element for Emergency Alerts -->
			        <!-- Fix for IE 11  */}
            <audio id="emergencyAlertSound" loop>
              <source src={alertSound} type="audio/mp3" />
            </audio>
            <MapRightClickMenu />
            <DeviceDataDisplay history={props.history || 0} />
            <DeviceClusterDisplay history={props.history || 0} />
            <HistoryTrailDataDisplay history={props.history || 0} />
            <PoiDataDisplay history={props.history || 0} />
            <PoiClusterDisplay history={props.history || 0} />
            <VideoSoftDataDisplay history={props.history || 0} />
            <VideoSoftClusterDisplay history={props.history || 0} />
            <GeofenceDataDisplay history={props.history || 0} />
            <ChatOverlay />
            <MapUtilities
              history={props.history || 0}
            />
            <MapZoomButtons history={props.history || 0} />
          </div>

          <div id="audioConfirmOverLay" />
          <Paper elevation={3} id="audioConfirmBox">
            <Typography id="audioConfirmText">
              {Language.translate(
                "Please Click Button To Give Browser Permission To Play Sound"
              )}
            </Typography>
            <Button
              variant="contained"
              id="audioConfirmButton"
              onClick={handleSoundClick}
            >
              OK
            </Button>
          </Paper>
        </>
      )}
    </React.Fragment>
  );
}

import React, { useState, useContext, useEffect } from "react";
import MapOverlayPoiCategory from "./MapOverlayPoiCategory";
import "./styles/MapOverlays.scss";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import { VideoSoftContext } from "@Modules/video_soft/context/VideoSoftContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  poiCategory: {
    display: "block",
    border: "1px solid red",
    "&.MuiListItemSecondaryAction-root": {
      top: 5,
      right: 0,
    },
  },
}));

const Language = require("sccLanguage").default;

export default function MapOverlays(props) {
  const OlMap =
    props.history == 1
      ? require("sccOlMapHistory").default
      : require("sccOlMapNew").default;

  
  const [overlays, setOverlays] = useState(null);
  const { isVideoSoftLoggedIn } = useContext(VideoSoftContext);

  const getOverlays = () => {
    const mapOverlays = OlMap.map.getLayers().getArray()[1];
    const newOverlays = mapOverlays.getLayers().getArray();

    setOverlays([...newOverlays]);
  };

  useEffect(() => {
    getOverlays();
  }, []);

  const toggleLayerVisibility = (layer) => {
    let visibility = true;
    visibility = !layer.getVisible();
    layer.setVisible(visibility);

    getOverlays();
  };

  const classes = useStyles();

  const handleToggle = (layer) => () => {
    switch (layer.get("title")) {
      case "Assets":
      case "POI":
      case "Geofences":
      case "VideoSoft":
        toggleLayerVisibility(layer);

        return;
      default:
        return;
    }
  };

  return (
    <div className="panel mapOverlays">
      <h5>{Language.translate("Map Layers")}</h5>

      <List className={`mapOverlaysListBox ${classes.root}`} dense={false}>
        {overlays?.map((layer, id) => {
          const labelId = `checkbox-list-label-${layer.get("title")}`;
          if (layer.get("title") === "VideoSoft" && !isVideoSoftLoggedIn) {
            return "";
          }
          return layer.get("title") ? (
            <ListItem
              key={id}
              className="mapOverlaysListBoxItem"
              role={undefined}
              dense
              onClick={handleToggle(layer)}
            >
              <ListItemIcon className="mapOverlaysListBoxItemCheckbox">
                <Checkbox
                  checked={layer.getVisible()}
                  color="primary"
                  name={layer.get("title")}
                />
              </ListItemIcon>
              <ListItemText
                className="mapOverlaysListBoxItemTitle"
                id={labelId}
                primary={Language.translate(layer.get("title"))}
              />

              {layer.get("title") === "POI" && layer.getVisible() ? (
                <ListItemSecondaryAction className={classes.poiCategory}>
                  <MapOverlayPoiCategory
                    poiCategoryArr={props.poiCategoryArr}
                    setPoiCategoryArr={props.setPoiCategoryArr}
                    {...props}
                  />
                </ListItemSecondaryAction>
              ) : (
                ""
              )}
            </ListItem>
          ) : (
            ""
          );
        })}
      </List>
    </div>
  );
}

import { BrowserRouter, Switch, Route } from "react-router-dom";
import LoginPage from "./modules/login/components/LoginPage";
import Main from "./modules/main/components/Main";
import History from "./modules/main/components/History";
import Report from "./modules/main/components/Report";
import ExtendedMaps from "./modules/main/components/ExtendedMaps";
import ResetPassword from "./modules/main/components/ResetPassword";
import Register from "./modules/main/components/Register";
import StreamPopup from "./modules/video_soft/components/StreamPopup";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/index.html" component={LoginPage} />
        <Route exact path="/t24.html" component={Main} />
        <Route exact path="/history.html" component={History} />
        <Route exact path="/videoSoft/:encryption" component={StreamPopup} />
        <Route exact path="/report.html" component={Report} />
        <Route exact path="/extended_map.html" component={ExtendedMaps} />
        <Route exact path="/reset_password/:token" component={ResetPassword} />
        <Route exact path="/user/register/:token" component={Register} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

import React, { Component } from "react";

// Internal
import DataDisplayButtonGroup from "@videoSoft/components/DataDisplay/DataDisplayButtonGroup";
import DataDisplayHeader from "@videoSoft/components/DataDisplay/DataDisplayHeader";
import VideoSoftInfo from "@videoSoft/components/DataDisplay/VideoSoftInfo";
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";

// Material-UI

const useStyles = {
  tableContainer: {
    display: "grid",
    flexDirection: "column",
    border: "1px solid #ddd",
  },
};

export default class VideoSoftDataDisplay extends Component {
  constructor() {
    super();
    this.state = {
      gateway: null,
    };

    if (!VideoSoftDataDisplay.publicInstance) {
      VideoSoftDataDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected(id) {
    setTimeout(() => {
      VideoSoftDataDisplay.publicInstance.setFeatureSelected(id);
    });
  }

  setFeatureSelected(id) {
    const selectedId = id || VideoSoftOverlay.getSelectedId();

    if (selectedId) {
      const videoSofts = this.props.videoSofts;

      const gw = videoSofts?.find((vs) => vs.id == selectedId);

      console.log("gw", gw);

      this.setState({ gateway: gw });
    }
  }

  render() {
    return (
      <div style={useStyles.tableContainer}>
        <DataDisplayHeader gateway={this.state.gateway} />
        <DataDisplayButtonGroup gateway={this.state.gateway} />
        <VideoSoftInfo gateway={this.state.gateway} />
      </div>
    );
  }
}

import React, { useState, useContext, useEffect } from "react";
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleListing } from "@Styles/ModuleListing";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";

// Internal
import Language from "sccLanguage";
import Utils from "sccUtils";
import Profile from "sccProfile";
import { VideoSoftContext } from "@videoSoft/context/VideoSoftContext";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  root: {
    padding: "20px",
    height: "370px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    margin: "15px",
  },
  loginContainer: {
    display: "grid",
    justifyItems: "center",
    margin: "10px",
  },
  loginFiled: {
    margin: "7px",
    width: "55%",
  },
  logInBtn: {
    width: "25%",
  },
  logError: {
    color: "red",
    textAlign: "center",
  },
}));

export default function VideoSoftLogIn() {
  const classes = useStyles();

  const { getVideoSoftStatus, setVideoSoftServer, setVideoSoftAuth } =
    useContext(VideoSoftContext);
  const [errorLogin, setErrorLogin] = useState("");

  const handleUserName = (event) => {
    const username = event.target.value;
    formik.setFieldValue("username", username);
  };

  const handlePassword = (event) => {
    const password = event.target.value;
    formik.setFieldValue("password", password);
  };

  const handleServer = (event) => {
    const server = event.target.value;
    formik.setFieldValue("server", server);
  };

  const handleRemember = (event) => {
    const rememberMe = event.target.checked;
    formik.setFieldValue("rememberMe", rememberMe);
  };

  const formik = useFormikContext();

  const handleLogIn = async () => {
    if (Object.keys(formik?.errors).length === 0) {
      const auth = btoa(formik.values.username + ":" + formik.values.password);
      const toStore = JSON.stringify({
        userId: Profile.get("id"),
        auth,
        serverUrl: formik.values.server,
      });
      const encrpted = await Utils.encryptMsg(toStore);

      const result = await getVideoSoftStatus(
        encrpted,
        auth,
        formik.values.username,
        formik.values.server
      );

      if (!result) {
        setErrorLogin("LOGIN ERROR");
      } else if (result) {
        setErrorLogin("");
        if (formik.values.rememberMe) {
          if (encrpted) {
            const dateAfter1Week = new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000
            );

            Utils.setCookie("videoSoft", encrpted, {
              path: "/",
              expires: dateAfter1Week,
            });
          }
        }

        formik.resetForm({});
      }
    }
  };

  return (
    <div>
      <Grid className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">
            {Language.translate("Videosoft Log In")}
          </Typography>
          <AssignmentIndRoundedIcon style={{ fontSize: "18px" }} />
        </div>
        <Grid className={classes.loginContainer}>
          <TextField
            className={classes.loginFiled}
            label="username"
            name="username"
            autoFocus
            onChange={(e) => handleUserName(e)}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            className={classes.loginFiled}
            label="password"
            type="password"
            name="password"
            onBlur={formik.handleBlur}
            onChange={(e) => handlePassword(e)}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            className={classes.loginFiled}
            label="serverUrl"
            type="server"
            name="server"
            onBlur={formik.handleBlur}
            onChange={(e) => handleServer(e)}
            error={formik.touched.server && Boolean(formik.errors.server)}
            helperText={formik.touched.server && formik.errors.server}
          />
        </Grid>

        <Grid className={classes.loginContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(formik.values?.rememberMe)}
                color="primary"
                name="rememberMe"
                onBlur={formik.handleBlur}
                onChange={(e) => handleRemember(e)}
              />
            }
            label="Remember me"
          />
        </Grid>
        <Grid className={classes.loginContainer}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.logInBtn}
            onClick={() => {
              handleLogIn();
            }}
          >
            {Language.translate("Log In")}
          </Button>
        </Grid>
        <Typography className={classes.logError}>{errorLogin}</Typography>
      </Grid>
    </div>
  );
}

import React from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import OndemandVideoRoundedIcon from "@material-ui/icons/OndemandVideoRounded";

// Internal
import CustomTooltip from "@Components/CustomTooltip";
import Language from "sccLanguage";
import VideoSoftOverlay from "@videoSoft/scripts/VideoSoftOverlay";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    width: "100%",
  },
  videoDisplayHeader: {
    backgroundColor: theme.palette.colors.gray.main,
    color: alpha(theme.palette.colors.black.main, 0.85),
  },

  videoToolBar: {
    padding: "2px 0px",
    border: "1px solid #ddd",
    minHeight: 25,
    Width: "100%",
    backgroundColor: alpha(theme.palette.colors.gray.light, 0.2),
  },

  videoIcon: {
    backgroundColor: "transparent",
    color: theme.palette.colors.black.main,
    width: 32,
    height: 25,
    marginLeft: 3,
    "& > svg": {
      fontSize: 12,
    },
  },

  title: {
    flexGrow: 1,
    width: "100%",
    fontSize: 16,
    marginLeft: 3,
  },

  closeButton: {
    minHeight: 30,
    minWidth: 30,
    height: 30,
    width: 30,
    color: theme.palette.colors.black.main,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: alpha(theme.palette.colors.black.main, 0.85),
    },
  },
}));

export default function ClusterDisplayHeader() {
  const classes = useStyles();

  const handleClose = () => {
    VideoSoftOverlay.hideClusterPopup();
    VideoSoftOverlay.deselectFeature();
  };

  return (
    <div className={classes.container}>
      <AppBar position="static" className={classes.videoDisplayHeader}>
        <Toolbar className={classes.videoToolBar}>
          <Avatar className={classes.videoIcon}>
            <OndemandVideoRoundedIcon className={classes.videoIcon} />
          </Avatar>

          <Typography variant="h6" className={classes.title}>
            Video Soft
          </Typography>

          <CustomTooltip
            title={Language.translate("Close")}
            arrow
            placement="top"
          >
            <Button
              size="medium"
              color="inherit"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon className={classes.poiCloseIcon} />
            </Button>
          </CustomTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import { default as Module } from "sccModule";

/**
 * The base class for VideoSoft
 *
 * @class VideoSoft
 */
class VideoSoftModule extends Module.Module {
  constructor() {
    super({
      moduleName: "videoSoft",
    });
  }

  init() {
    return Module.Module.prototype.init.call(this);
  }

  openWindow(params) {
    const GuiUtils = require("sccGuiUtils").default;

    let url = "";

    if(params) {
      url += params
    }

    const pageRef = GuiUtils.openPopupWindow("videoSoft" + url, "VideoSoft");
  }
}

export default new VideoSoftModule();

// runs the function on initial load
/**
 * initializes the Reports feature
 */
import _ from "lodash";
import moment from "moment";

const Historic = require("sccHistory").default;
const Device = require("sccDevice").default;
const Language = require("sccLanguage").default;
const Utils = require("sccUtils").default;

class Reports {
  constructor() {
    this.moduleName = "report";
    this.routeUrl = Utils.apiUrlPrefix + "/report";
    this.pageUrl = "report.html";

    this.loadingReports = false;

    this.filter = {};
    this.showTable = false;
    this.reportTimePeriod = 0;
    this.setStartTimeStamp = 0;
    this.showLatestReportsTable = 0;
    this.reportType = "latest";
    this.title = "Reports";
    this.sortType = 0;

    this.historicData = [];

    this.pagination = {
      currentPage: 1,
      itemsPerPage: 20,
      numberofItems: null,
    };

    this.itemsPerPageOptions = {
      10: 10,
      20: 20,
      50: 50,
      100: 100,
    };
  }

  init() {
    this.latestReports();
    return Promise.resolve();
  }

  openWindow(pageUrl) {
    const GuiUtils = require("sccGuiUtils").default;
    if (!pageUrl) {
      // to prevent loading a previously loaded asset when window opens
      this.selectedAssetId = null;
      this.selectedAssetName = null;
    }

    var url = "";
    if (typeof pageUrl != "undefined") {
      url = pageUrl;
    }

    if (typeof this.openWindow.windowRefs == "undefined") {
      this.openWindow.windowRefs = {};
    }
    if (
      typeof this.openWindow.windowRefs["Report"] == "undefined" ||
      this.openWindow.windowRefs["Report"].closed
    ) {
      this.openWindow.windowRefs["Report"] = GuiUtils.openPopupWindow(
        this.pageUrl + url,
        "Report"
      );
    } else {
      this.openWindow.windowRefs["Report"].focus();
    }
  }

  translateTitle() {
    document.title =
      Language.translate("Reports") +
      " | SCCTitan " +
      Language.translate("Platform");
  }

  /*
   * If Position / Alarms is selected as report option then run this function
   */
  position() {
    const devices = Historic.queryParams.devices || [];
    const startTimestamp = moment(Historic.queryParams.startTimestamp).unix();
    const endTimestamp = moment(Historic.queryParams.endTimestamp).unix();

    this.title = Language.translate("Position/Alerts");
    //Retain the timestamp to use for filter so the data does not change when changing date
    this.setStartTimeStamp = startTimestamp;

    if (!devices || !startTimestamp || !endTimestamp) {
      alert("did not receive proper arguments to calculate history");
      throw new Error("did not receive proper arguments to calculate history");
    }

    this.loadingReports = true;
    this.showTable = false;
    this.sortType = "event_timestamp";
    Historic.loadData(devices, startTimestamp, endTimestamp).then(() => {
      this.filterData(Historic.get(), false);
      this.title = Language.translate("Position/Alerts");
      this.historicData = this.getFilteredHistoric();
      this.getAlertViolationsForReport();
    });
  }

  getFilteredHistoric() {
    const startTimestamp = this.setStartTimeStamp;
    console.log('startTime', startTimestamp, Historic.get());
    return _.filter(Historic.get(), (event) => {
      event.device_name = Device.get(event.device_id).name;
      event.device_type = Device.get(event.device_id).type;
      return event.event_timestamp >= startTimestamp;
    });
  }

  /*
		Finds on-going alert violations during report events of each device
	*/
  getAlertViolationsForReport() {
    const historicEvents = _.groupBy(Historic.get(), "device_id");

    const deviceEvents = {};

    //loops through each historic event, groups events per device and processes the alert violations
    _.each(historicEvents, (events, deviceId) => {
      this.buildEventsObjectForDevice(deviceId, events, deviceEvents);

      /*
				Loops through each alert event for a device to process 
				and append the alert violations to the reports
				if alert is still on-going at the time of report
			*/
      _.each(deviceEvents[deviceId].alert, (alertEvent) => {
        const alertInfo = {
          start_timestamp: null,
          end_timestamp: null,
          alert_type: null,
        };

        this.getInfoForAlertEvent(alertInfo, alertEvent);
        this.appendOngoingAlertViolationsToReport(
          deviceId,
          alertInfo,
          deviceEvents
        );
      });
    });

    return;
  }

  //appends and builds the device events object
  buildEventsObjectForDevice(deviceId, events, deviceEvents) {
    deviceEvents[deviceId] = _.groupBy(events, "event");
    deviceEvents[deviceId].alert = [];

    _.each(deviceEvents[deviceId], (evt, type) => {
      if (type !== "report" && type !== "alert") {
        deviceEvents[deviceId].alert = _.concat(
          deviceEvents[deviceId].alert,
          evt
        );
      }
    });

    deviceEvents[deviceId] = _.omit(deviceEvents[deviceId], [
      "emergency",
      "speed",
      "non_report",
      "cargo",
      "geofence",
    ]);

    deviceEvents[deviceId].alert = _.groupBy(
      deviceEvents[deviceId].alert,
      "alert_id"
    );
    deviceEvents[deviceId].report = _.keyBy(
      deviceEvents[deviceId].report,
      "report_id"
    );
  }

  //Gets info of start time, end time and alert type for each alert event of the device
  getInfoForAlertEvent(alertInfo, alertEvent) {
    _.forEach(alertEvent, (alert) => {
      if (alert.alert_started === true) {
        alertInfo.start_timestamp = alert.event_timestamp;
      } else {
        alertInfo.end_timestamp = alert.event_timestamp;
      }
      alertInfo.alert_type = alert.event;
    });

    return;
  }

  //appends the alert violations to the reports if alert is still on-going at the time of report
  appendOngoingAlertViolationsToReport(deviceId, alertInfo, deviceEvents) {
    _.forEach(deviceEvents[deviceId].report, (report) => {
      if (report.alerts === undefined) {
        report.alerts = {};
      }

      if (alertInfo.start_timestamp == null) {
        report.alerts[alertInfo.alert_type] = { alert_started: true };
      }

      if (alertInfo.end_timestamp == null) {
        if (report.event_timestamp >= alertInfo.start_timestamp) {
          report.alerts[alertInfo.alert_type] = { alert_started: true };
        }
      }

      if (
        alertInfo.start_timestamp != null &&
        alertInfo.end_timestamp != null
      ) {
        if (
          report.event_timestamp >= alertInfo.start_timestamp &&
          report.event_timestamp < alertInfo.end_timestamp
        ) {
          report.alerts[alertInfo.alert_type] = { alert_started: true };
        }
      }
    });
    return;
  }

  /*
   * If Latest Reports is selected as report option then run this function
   */
  latestReports() {
    this.loadingReports = true;
    this.showTable = false;
    const startTimestamp = moment(Historic.queryParams.startTimestamp).unix();
    this.setStartTimeStamp = startTimestamp;
    this.filterData(Device.get(), true);
    this.title = Language.translate("Latest Reports");
    this.sortType = "report_timestamp";
  }

  filterData(obj, showLatestReports) {
    if (obj == null) {
      this.loadingReports = false;
      this.showTable = false;
      return false;
    }
    if (Object.keys(obj).length !== 0) {
      this.loadingReports = false;
      this.showTable = true;
      this.showLatestReportsTable = showLatestReports;
    } else {
      this.loadingReports = false;
      this.showTable = false;
    }
  }

  /**
   * closes the history window
   */
  closeWindow() {
    var winRef = window.open("", "Report", "", true);
    if (winRef != null) {
      winRef.close();
    }
  }

  getEventCoordinate(evt) {
    const OlMap = require("sccOlMapNew").default;

    let coord = null;
    if (evt.event === "report") {
      coord = [evt.longitude, evt.latitude];
      return OlMap.formatCoordinate(coord);
    } else {
      if (evt.report_id == null) return "N/A";
      setTimeout(() => {
        //timeout intrduced as a temp fix as Historic is not updated at this time
        const reportEvent = Historic.getReportById(evt.report_id);
        coord = reportEvent
          ? [reportEvent.longitude, reportEvent.latitude]
          : [reportEvent.longitude, reportEvent.latitude];
        return OlMap.formatCoordinate(coord);
      }, 2000);
    }
  }

  getStatusOfEvent(event) {
    let status = "End";

    if (event.alert_started === true) {
      status = "Start";
    }

    return status;
  }
}

export default new Reports();

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";

// Material-UI
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles, Box } from "@material-ui/core";
import {
  Grid,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
  FormControl,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@material-ui/core";
import PauseRoundedIcon from "@material-ui/icons/PauseRounded";
import FastRewindRoundedIcon from "@material-ui/icons/FastRewindRounded";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import FastForwardRoundedIcon from "@material-ui/icons/FastForwardRounded";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import RemoveIcon from "@material-ui/icons/Remove";
import CropOriginalOutlinedIcon from "@material-ui/icons/CropOriginalOutlined";
import { grey, blue } from "@material-ui/core/colors";
import Language from "sccLanguage";
import Images from "sccImage";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  // layout
  container: {
    display: "grid",
    overflow: "overlay",
    gap: 2,
    width: "100%",
    height: "100vh",
    gridTemplateColumns: "300px 1fr 300px",
    gridTemplateRows: "auto",
    gridTemplateAreas: `"header header header"
                      "main main select"
                      "main main zoom"
                      "main main panel"
                      "main main gps"
                      "footer footer footer"`,

    backgroundColor: theme.palette.colors.white.main,
  },
  header: {
    gridArea: "header",
    display: "flex",
    position: "relative",
    padding: "3px",
    margin: "10px 34px -3px",
  },
  main: {
    gridArea: "main",
    marginLeft: "7px",
  },
  videoBox: {
    display: "flex",
    justifyContent: "center",
    minWidth: "95%",
  },
  videofr: {
    height: "95%",
    maxWidth: "95%",
    justifycontent: "center",
    alignItem: "center",
    padding: "10px",
  },

  playBtnContainer: {
    marginLeft: "5px",
    textAlign: "center",
  },
  playButton: {
    margin: "0px 5px",
    padding: "3px",
    height: "100%",
    fontSize: 26,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.light,
    },
  },
  playIconBtn: {
    color: blue[800],
    fontSize: 35,
  },
  disablePlayBtn: {
    color: "#e0e0e0",
    fontSize: 35,
  },
  select: {
    gridArea: "select",
  },
  zoom: {
    gridArea: "zoom",
  },
  panel: {
    gridArea: "panel",
  },
  footer: {
    gridArea: "footer",
    display: "flex",
    alignSelf: "flex-end",
    padding: "10px",
    margin: "10px",
  },

  // custom select
  formControl: {
    display: "flex",
    margin: theme.spacing(1),
    minWidth: 120,
  },

  // cursor camera btn
  arrowButton: {
    color: grey[900],
    fontSize: 26,
  },
  cursorBtn: {
    display: "grid",
    justifySelf: "center",
    alignItems: "center",
    justifyItems: "center",
    width: "57%",
    aspectRatio: "1",
    border: "3px solid #e0e0e0",
    backgroundColor: "#f5f5f5",
    borderRadius: "50%",
    gridTemplateColumns: "54px 1fr 54px",
    gridTemplateRows: "auto",
    gridTemplateAreas: `"top top top"
                      "middle middle middle"
                      "down down down"`,
  },
  circleBtn: {
    alignContent: "center",
    textAlign: "center",
    cursor: "pointer",
    width: "100%",
    height: "100%",
  },
  top: {
    gridArea: "top",
  },
  middle: {
    gridArea: "middle",
  },
  down: {
    gridArea: "down",
  },

  zoomButton: {
    width: "97%",
    color: "#fff",
    margin: "1px 3px",
    borderRadius: "5px",
    backgroundColor: "#01579b",
    "&:hover": {
      backgroundColor: "#0277bd",
    },
    fontWeight: "550",
  },

  presetAddButton: {
    color: "#fff",
    height: "37px",
    margin: "0 9px",
    width: "27%",
    borderRadius: "5px",
    backgroundColor: "#01579b",
    "&:hover": {
      backgroundColor: "#0277bd",
    },
    fontWeight: "550",
  },
  presetDeleteButton: {
    color: "#fff",
    width: "30%",
    height: "37px",
    padding: "8px",
    marginRight: "4px",
    borderRadius: "5px",
    backgroundColor: "#01579b",
    "&:hover": {
      backgroundColor: "#0277bd",
    },
    fontWeight: "550",
  },
  presetUpdateButton: {
    color: "#fff",
    width: "30%",
    height: "37px",
    borderRadius: "5px",
    margin: "5px",
    backgroundColor: "#01579b",
    "&:hover": {
      backgroundColor: "#0277bd",
    },
    fontWeight: "550",
  },
  disabledBtn: {
    width: "30%",
    height: "37px",
    padding: "8px",
    marginRight: "7px",
    borderRadius: "5px",
    backgroundColor: "#e0e0e0",
    fontWeight: "550",
  },

  homeButton: {
    width: "48%",
    marginLeft: "3px",
    borderRadius: "5px",
  },
  buttonCon: {
    margin: "2px",
  },

  //control panel btn
  pipButton: {
    width: "48%",
    marginLeft: "3px",
  },
  exportBtn: {
    margin: "3px",
    width: "97%",
  },
  modeBtn: {
    width: "48%",
    margin: "5px 1px 4px 3px",
    padding: "4px 5px",
    fontWeight: "700",
    color: "white",
    borderRadius: "5px",
    backgroundColor: "#01579b",
    "&:hover": {
      backgroundColor: "#0277bd",
    },
  },
  digitalButton: {
    marginLeft: "3px",
    marginTop: "1px",
    width: "48%",
  },
  jpegBtn: {
    borderRadius: "5px",
    marginLeft: "3px",
    width: "48%",
    height: "32px",
  },
  text: {
    margin: "3px",
    backgroundColor: "#f6f5f5",
  },
  //logo size
  logoVideo: {
    width: "110px",
    height: "50px",
    position: "absolute",
  },
  videoSoftTitle: {
    display: "flex",
    flex: "1",
    justifyContent: "space-around",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderColor: theme.palette.colors.gray.dark,
    color: "black",
    fontWeight: "700",
    padding: "3px 3px",
    border: "3px solid black",
    backgroundColor: "white",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.main,
    },
  },
}))(Button);

export default function StreamPopup() {
  const classes = useStyles();

  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [profiles, setProfiles] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState("1");
  const [cameras, setCameras] = useState([]);
  const [selectedCameraId, setSelectedCameraId] = useState("1");
  const [presets, setPresets] = useState([]);
  const [selectedPresetId, setSelectedPresetId] = useState("");
  const [selectedPreset, setSelectedPreset] = useState("");
  const [showPresetNameDialog, setShowPresetNameDialog] = useState(false);
  const [presetName, setPresetName] = useState("");
  const [oldPresetName, setOldPresetName] = useState("");
  const [isPresetUpdate, setIsPresetUpdate] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);

  const [machineId, setMachineId] = useState(null);
  const [streamId, setStreamId] = useState(null);
  const [auth, setAuth] = useState(null);
  const [name, setName] = useState(null);
  const [server, setServer] = useState(null);

  let quality = "0"; // demo default quality
  let videoRequested = false;

  const cameraHandleChange = (event) => {
    const id = event.target.value;
    setSelectedCameraId(id);
    SwitchCamera(id);

    const dateAfter1Week = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

    Utils.setCookie("videoSoftCamera", id, {
      path: "/",
      expires: dateAfter1Week,
    });
  };
  const profileHandleChange = (event) => {
    const id = event.target.value;
    setSelectedProfileId(id);
    SwitchProfile(id);

    const dateAfter1Week = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

    Utils.setCookie("videoSoftProfile", id, {
      path: "/",
      expires: dateAfter1Week,
    });
  };
  const presetHandleChange = (event) => {
    const presetItem = event.target.value;
    const { index, name } = JSON.parse(presetItem);

    if (name) {
      setSelectedPresetId(index);

      setSelectedPreset(presetItem);

      setOldPresetName(name);

      GotoPreset(index);
    } else {
      setSelectedPreset(presetItem);
      setOldPresetName("");
      setSelectedPresetId("");
    }
  };

  const playButtonHandle = (play) => {
    if (play === 0) {
      setPlayVideo(true);
      PlaySpeed(1);
    } else if (play === 1) {
      setPlayVideo(false);
      PlaySpeed(0);
    }
  };

  function PTZ(pan, tilt, zoom) {
    fetch(
      server +
        "/vsapi/ptz?machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&pan=" +
        pan +
        "&tilt=" +
        tilt +
        "&zoom=" +
        zoom +
        "&auth=" +
        auth
    );
  }
  function DigitalPTZ(enable) {
    if (enable)
      fetch(
        server +
          "/vsapi/ptz?machineid=" +
          machineId +
          "&streamid=" +
          streamId +
          "&digitalptz=true" +
          "&auth=" +
          auth
      );
    else
      fetch(
        server +
          "/vsapi/ptz?machineid=" +
          machineId +
          "&streamid=" +
          streamId +
          "&digitalptz=false" +
          "&auth=" +
          auth
      );
  }

  function GotoHome() {
    fetch(
      server +
        "/vsapi/preset?action=gotohome&machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&auth=" +
        auth
    );
  }
  function SetHome() {
    fetch(
      server +
        "/vsapi/preset?action=sethome&machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&auth=" +
        auth
    );
  }
  function GotoPreset(presetId) {
    fetch(
      server +
        "/vsapi/preset?action=gotopreset&machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&value=" +
        presetId +
        "&auth=" +
        auth
    );
    if (presetId >= 0) {
      // move the dropdown back to the first item. Otherwise the user is unable to pick the same preset again
      // 0 is the "Select Preset" entry in the dropdown (used as a hint)
      //  let select = document.getElementById("presetsSelect");
      //  select.selectedIndex = 0;
    }
  }
  function SwitchCamera(cameraId) {
    if (cameraId > 0)
      fetch(
        server +
          "/vsapi/stream?machineid=" +
          machineId +
          "&streamid=" +
          streamId +
          "&camera=" +
          cameraId +
          "&auth=" +
          auth
      );
  }
  function SwitchProfile(profileId) {
    if (profileId > 0)
      fetch(
        server +
          "/vsapi/stream?machineid=" +
          machineId +
          "&streamid=" +
          streamId +
          "&profile=" +
          profileId +
          "&auth=" +
          auth
      );
  }
  function PictureInPicture(enable) {
    if (enable)
      fetch(
        server +
          "/vsapi/stream?machineid=" +
          machineId +
          "&streamid=" +
          streamId +
          "&pip=true" +
          "&auth=" +
          auth
      );
    else
      fetch(
        server +
          "/vsapi/stream?machineid=" +
          machineId +
          "&streamid=" +
          streamId +
          "&pip=false" +
          "&auth=" +
          auth
      );
  }

  function GotoLive() {
    fetch(
      server +
        "/vsapi/stream?live=true&machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&auth=" +
        auth
    );
  }

  async function GotoReplay() {
    let now = new Date();
    // subtract 5 seconds
    let replayTime = new Date(now.getTime() - 5 * 1000);
    await fetch(
      server +
        "/vsapi/stream?replay=" +
        replayTime.toISOString() +
        "&machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&auth=" +
        auth
    );
    // and pause
    await fetch(
      server +
        "/vsapi/stream?replayspeed=0 &machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&auth=" +
        auth
    );
  }

  function DownloadLast10() {
    // Example http://hostname:8900/vsapi/download?&machineid=abc123&cameraid=1&start=2023-01-30T08:00:00&end=2023-01-30T08:05:30

    const now = new Date();
    const start = new Date(now.getTime() - 12 * 1000); // 12 seconds in the past
    const end = new Date(now.getTime() - 2 * 1000); // 2 seconds in the past
    let url =
      server +
      `/vsapi/download?cameraid=${selectedCameraId}&start=${start.toISOString()}&end=${end.toISOString()}&machineid=${machineId}` +
      "&auth=" +
      auth;
    window.open(url);
  }

  function QuickJPEG() {
    let url =
      server +
      "/snapshot.jpg?machineid=" +
      machineId +
      "&streamid=" +
      streamId +
      "&auth=" +
      auth;
    window.open(url);
  }

  function HQJPEG() {
    let url =
      server +
      "/vsapi/stream?hqsnapshot=true&machineid=" +
      machineId +
      "&streamid=" +
      streamId +
      "&auth=" +
      auth;
    window.open(url);
  }

  function PlaySpeed(speed) {
    fetch(
      server +
        "/vsapi/stream?replayspeed=" +
        speed +
        "&machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&auth=" +
        auth
    );
  }

  function GetVideo() {
    fetch(
      server +
        "/video.mjpg?machineid=" +
        machineId +
        "&streamid=" +
        streamId +
        "&q=" +
        quality +
        "&auth=" +
        auth
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // For a simple fetch we can access response.blob
        // For MJPEG we will add a body reader
        if (!response.body) {
          throw new Error(`HTTP error! Body not found`);
        }

        const reader = response.body.getReader();
        let imageBuffer = [];
        let lastByte = -1;
        let startFound = false;
        let imageTag = document.getElementById("image");
        let mjpegresultsTag = document.getElementById("mjpegresults");
        let objectURL = null;
        let fpsCalcStartTime = null;
        let fpsCalcFrameCount = 0;
        let fpsCalcBytesReceived = 0;

        // create read() function. This will call itself.
        function read() {
          reader
            .read()
            .then(({ done, value }) => {
              if (done) return;

              // search through the value (a Byte Array) looking for 255 and 216 (FF D8)
              for (let i = 0; i < value.length; i++) {
                if (startFound == false && lastByte == 255 && value[i] == 216) {
                  startFound = true;
                  imageBuffer = [255, 216];
                } else {
                  //startFound == true
                  // Append each byte
                  imageBuffer.push(value[i]);
                  // check for end marker
                  if (lastByte == 255 && value[i] == 217) {
                    // Display the JPEG

                    // Free the old ObjectURL resource (have also seen an image.onload handler that does this)
                    if (objectURL != null) URL.revokeObjectURL(url);

                    // convert untyped dynamic length array to Uint8Array (fixed length array)
                    const uint8Array = new Uint8Array(imageBuffer);

                    const blob = new Blob([uint8Array.buffer], {
                      type: "image/jpeg",
                    });
                    const url = URL.createObjectURL(blob);

                    // Render the JPEG
                    imageTag.src = url;

                    // Compute the MJPEG FPS and Bitrate
                    const now = new Date();

                    if (fpsCalcStartTime == null) {
                      // first time
                      fpsCalcStartTime = now;
                      fpsCalcFrameCount = 0;
                      fpsCalcBytesReceived = 0;
                    } else {
                      fpsCalcFrameCount++;
                      fpsCalcBytesReceived += imageBuffer.length;

                      const timespan_ms = now - fpsCalcStartTime;

                      if (timespan_ms > 1500 && fpsCalcFrameCount > 1) {
                        const fps = fpsCalcFrameCount / (timespan_ms / 1000);
                        const bytesPerSecond =
                          fpsCalcBytesReceived / (timespan_ms / 1000);
                        const kbps = (bytesPerSecond * 8) / 1024;
                        // Use Math.round to round to 1 decimal place
                        mjpegresultsTag.innerHTML =
                          "MJPEG Stream FPS " +
                          Math.round(fps * 10) / 10 +
                          " Bitrate: " +
                          Math.round(kbps * 10) / 10 +
                          "kbps";
                        fpsCalcStartTime = now;
                        fpsCalcFrameCount = 0;
                        fpsCalcBytesReceived = 0;
                      }
                    }

                    // Clean up
                    imageBuffer = [];
                    lastByte = -1;
                    startFound = false;
                  }
                }
                lastByte = value[i];
              } // end for

              read(); // call ourselves to read read the next piece of data
            })
            .catch((err) => {
              // error. Do not all read() again
            });
        } // end of 'read' function definition

        // Start the first read
        read();
      }) // end of 'then'
      .catch((err) => {
        // Error with 'fetch'
        console.log("fetch failed " + err.toString());
      }); // end of fetch.then.catch
  } // end of GetVideo()

  function processStatusReply() {
    // This is a callback function. The 'this' for this callback has .responseText

    try {
      // Get the video now we have the first Status Message reply
      if (videoRequested == false) {
        videoRequested = true;
        GetVideo();
      }

      // Process the JSON data in "this.responseText"
      const data = JSON.parse(this.responseText);

      const gw = data.gateways.find((item) => item.machineid === machineId);
      const stream = gw.streams.find((item) => item.id == streamId);

      // cameras
      const cameras = gw.sources?.length > 0 ? gw.sources : [];
      setCameras(cameras);

      // profiles
      const profiles = gw.profiles?.length > 0 ? gw.profiles : [];
      setProfiles(profiles);

      // presets
      const presets = stream?.presets?.length > 0 ? stream?.presets : [];
      setPresets(presets);
    } catch {}

    // Request another /vsapi/status in 1 second
    setTimeout(GetStatus, 1000);
  }

  function requestError(err) {
    console.log("Fetch Error :-", err);
  }

  function GetStatus() {
    var oReq = new XMLHttpRequest();
    oReq.onload = processStatusReply; // call processStatusReply with a context (this) including a responseText object
    oReq.onerror = requestError;
    oReq.open(
      "get",
      server + "/vsapi/status?machineid=" + machineId + "&auth=" + auth,
      true
    );
    // oReq.open("get", "http://vms.videosoft.live:8080/vsapi/status?auth=eS53YW5nQE5vcnRhY0RlZmVuY2UuY29tOlBhc3N3b3JkMDQuMDk=", true);
    oReq.send();
  }

  function addPreset(e) {
    e.preventDefault();

    try {
      fetch(
        server +
          "/vsapi/preset?action=createpreset&name=" +
          presetName +
          "&machineid=" +
          machineId +
          "&streamid=" +
          streamId +
          "&auth=" +
          auth
      );

      setShowPresetNameDialog(false);
    } catch (err) {
      enqueueSnackbar("Error ADD PRESET", {
        variant: "error",
      });
    }
  }

  function updatePreset(e) {
    e.preventDefault();

    if (selectedPresetId) {
      try {
        fetch(
          server +
            "/vsapi/preset?action=updatepreset" +
            "&value=" +
            selectedPresetId +
            "&name=" +
            oldPresetName +
            "&newname=" +
            presetName +
            "&machineid=" +
            machineId +
            "&streamid=" +
            streamId +
            "&auth=" +
            auth
        );

        setShowPresetNameDialog(false);
      } catch (err) {
        enqueueSnackbar("Error UPDATE PRESET", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Error UPDATE PRESET", {
        variant: "error",
      });
    }
  }

  function removePreset(e) {
    e.preventDefault();

    if (selectedPresetId) {
      try {
        fetch(
          server +
            "/vsapi/preset?action=deletepreset" +
            "&value=" +
            selectedPresetId +
            "&name=" +
            oldPresetName +
            "&machineid=" +
            machineId +
            "&streamid=" +
            streamId +
            "&auth=" +
            auth
        );

        setSelectedPresetId(0);
      } catch (err) {
        enqueueSnackbar("Error REMOVE PRESET", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Error REMOVE PRESET", {
        variant: "error",
      });
    }
  }

  useEffect(async () => {
    // get params
    const encryption = params.encryption;

    if (encryption) {
      const decrypted = await Utils.decryptMsg(encryption);

      if (decrypted) {
        const { machineId, streamId, server, auth, title } =
          JSON.parse(decrypted);

        setMachineId(machineId || null);
        setStreamId(streamId || null);
        const serverUrl =
          decodeURIComponent(server) || "http://vms.videosoft.live:8080";
        setServer(serverUrl);

        setAuth(auth || null);
        setName(title || "");

        // get stream statue

        if (Utils.getCookie("videoSoftCamera")) {
          setSelectedCameraId(Utils.getCookie("videoSoftCamera"));
        }

        if (Utils.getCookie("videoSoftProfile")) {
          setSelectedProfileId(Utils.getCookie("videoSoftProfile"));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (machineId && server && auth) {
      GetStatus();
    }
  }, [machineId, server, auth]);

  return (
    <SnackbarProvider autoHideDuration={3000}>
      <Paper elevation={3}>
        <Dialog
          open={showPresetNameDialog}
          onClose={() => setShowPresetNameDialog(false)}
          aria-labelledby="set-preset-name"
        >
          <DialogTitle>{Language.translate("Add Preset")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {Language.translate("Please give name to the preset")}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="name"
              value={presetName}
              onChange={(e) => {
                setPresetName(e.target.value);
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowPresetNameDialog(false);
              }}
              color="primary"
            >
              {Language.translate("Cancel")}
            </Button>
            <Button
              onClick={(e) => {
                isPresetUpdate ? updatePreset(e) : addPreset(e);
              }}
              color="primary"
            >
              {isPresetUpdate ? "UPDATE" : "ADD"}
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.container}>
          <div className={classes.header}>
            <img
              className={classes.logoVideo}
              src={
                Images.getImageCollection("platform_images").scc_titan_logo
                  .default
              }
              alt="TITAN BY NORTAC"
            />
            <div className={classes.videoSoftTitle}>
              <Typography style={{ fontWeight: "500" }}>
                <Box fontFamily="Segoe UI" fontSize="h4.fontSize">
                  GATEWAY NAME: {name}
                </Box>
              </Typography>
            </div>
          </div>
          <div className={classes.main}>
            <Grid item xs={12} className={classes.videoBox}>
              <img
                id="image"
                alt="Live Video MJPEG Stream"
                className={classes.videofr}
              />
            </Grid>
            <Grid item xs={12} style={{ height: "20px", textAlign: "center" }}>
              <div id="mjpegresults">&nbsp;</div>
            </Grid>
            <Grid item xs={12} className={classes.playBtnContainer}>
              <IconButton
                color="primary"
                aria-label="FastRewindRoundedIcon"
                className={classes.playButton}
                onClick={() => PlaySpeed(-8)}
              >
                <FastRewindRoundedIcon className={classes.playIconBtn} /> 8X
              </IconButton>
              <IconButton
                color="primary"
                aria-label="FastRewindRoundedIcon"
                className={classes.playButton}
                onClick={() => PlaySpeed(-4)}
              >
                <FastRewindRoundedIcon className={classes.playIconBtn} /> 4X
              </IconButton>
              <IconButton
                color="primary"
                aria-label="FastRewindRoundedIcon"
                className={classes.playButton}
                onClick={() => PlaySpeed(-1)}
              >
                <FastRewindRoundedIcon className={classes.playIconBtn} /> 1X
              </IconButton>
              <IconButton
                color="primary"
                aria-label="PauseRoundedIcon"
                className={classes.playButton}
                onClick={() => playButtonHandle(1)}
              >
                <PauseRoundedIcon className={classes.playIconBtn} />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="PlayArrowRoundedIcon"
                className={classes.playButton}
                onClick={() => playButtonHandle(0)}
                disabled={playVideo}
              >
                <PlayArrowRoundedIcon
                  className={
                    playVideo ? classes.disablePlayBtn : classes.playIconBtn
                  }
                />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="FastRewindRoundedIcon"
                className={classes.playButton}
                onClick={() => PlaySpeed(2)}
              >
                <FastForwardRoundedIcon className={classes.playIconBtn} /> 2X
              </IconButton>
              <IconButton
                color="primary"
                aria-label="FastRewindRoundedIcon"
                className={classes.playButton}
                onClick={() => PlaySpeed(4)}
              >
                <FastForwardRoundedIcon className={classes.playIconBtn} /> 4X
              </IconButton>
              <IconButton
                color="primary"
                aria-label="FastRewindRoundedIcon"
                className={classes.playButton}
                onClick={() => PlaySpeed(8)}
              >
                <FastForwardRoundedIcon className={classes.playIconBtn} /> 8X
              </IconButton>
            </Grid>
          </div>
          <div className={classes.select}>
            <FormControl
              variant="filled"
              label="cameras"
              className={classes.formControl}
            >
              <InputLabel id="cameraSelect">
                {Language.translate("Select Camera Source")}
              </InputLabel>
              <Select
                id="cameraSelect"
                value={selectedCameraId}
                label="cameras"
                onChange={(e) => cameraHandleChange(e)}
              >
                {cameras.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="profileSelect">
                {Language.translate("Select profile")}
              </InputLabel>
              <Select
                id="profileSelect"
                value={selectedProfileId}
                label="profileSelect"
                onChange={(e) => profileHandleChange(e)}
              >
                {profiles.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="presetSelect">
                {Language.translate("Select preset")}
              </InputLabel>
              <Select
                id="presetSelect"
                value={selectedPreset}
                label="presetSelect"
                onChange={(e) => presetHandleChange(e)}
              >
                <MenuItem value={JSON.stringify({})}>
                  {Language.translate("none")}
                </MenuItem>
                {presets?.length > 0 ? (
                  presets.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={JSON.stringify({
                        index: item.index,
                        name: item.name,
                      })}
                    >
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled />
                )}
              </Select>
            </FormControl>
            <div>
              <Button
                variant="contained"
                size="small"
                className={classes.presetAddButton}
                onClick={(e) => {
                  setIsPresetUpdate(false);
                  setShowPresetNameDialog(true);
                }}
              >
                {Language.translate("Add")}
              </Button>
              {selectedPresetId ? (
                <Button
                  variant="contained"
                  size="small"
                  className={classes.presetDeleteButton}
                  onClick={(e) => {
                    setIsPresetUpdate(true);
                    setShowPresetNameDialog(true);
                  }}
                >
                  {Language.translate("Update")}
                </Button>
              ) : (
                <Button disabled className={classes.disabledBtn}>
                  {Language.translate("Update")}
                </Button>
              )}
              {selectedPresetId ? (
                <Button
                  variant="contained"
                  size="small"
                  className={classes.presetUpdateButton}
                  onClick={(e) => removePreset(e)}
                >
                  {Language.translate("Delete")}
                </Button>
              ) : (
                <Button disabled className={classes.disabledBtn}>
                  {Language.translate("Delete")}
                </Button>
              )}
            </div>
          </div>
          <div className={classes.zoom}>
            <Typography className={classes.text}>
              {Language.translate("Camera Cursor")}
            </Typography>
            <Grid container item xs={12} className={classes.cursorBtn}>
              <div></div>
              <div className={classes.circleBtn}>
                <IconButton
                  className={classes.top}
                  onMouseDown={() => PTZ(0, 50, 0)}
                  onMouseUp={() => PTZ(0, 0, 0)}
                >
                  <KeyboardArrowUpIcon className={classes.arrowButton} />
                </IconButton>
              </div>
              <div></div>
              <div className={classes.circleBtn}>
                <IconButton
                  className={classes.middle}
                  onMouseDown={() => PTZ(-50, 0, 0)}
                  onMouseUp={() => PTZ(0, 0, 0)}
                >
                  <KeyboardArrowLeftIcon className={classes.arrowButton} />
                </IconButton>
              </div>
              <div className={classes.circleBtn}>
                <IconButton onClick={() => GotoHome()}>
                  <HomeRoundedIcon className={classes.arrowButton} />
                </IconButton>
              </div>
              <div className={classes.circleBtn}>
                <IconButton
                  className={classes.middle}
                  onMouseDown={() => PTZ(50, 0, 0)}
                  onMouseUp={() => PTZ(0, 0, 0)}
                >
                  <KeyboardArrowRightIcon className={classes.arrowButton} />
                </IconButton>
              </div>
              <div></div>
              <div className={classes.circleBtn}>
                <IconButton
                  className={classes.down}
                  onMouseDown={() => PTZ(0, -50, 0)}
                  onMouseUp={() => PTZ(0, 0, 0)}
                >
                  <KeyboardArrowDownIcon className={classes.arrowButton} />
                </IconButton>
              </div>
            </Grid>
            <Grid className={classes.buttonCon}>
              <Button
                variant="contained"
                size="small"
                className={classes.zoomButton}
                endIcon={<AddSharpIcon />}
                onMouseDown={() => PTZ(0, 0, 50)}
                onMouseUp={() => PTZ(0, 0, 0)}
              >
                {Language.translate("Zoom")}
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.zoomButton}
                endIcon={<RemoveIcon />}
                onMouseDown={() => PTZ(0, 0, -50)}
                onMouseUp={() => PTZ(0, 0, 0)}
              >
                {Language.translate("Zoom")}
              </Button>
            </Grid>
            <Grid className={classes.buttonCon}>
              <ColorButton
                variant="outlined"
                className={classes.homeButton}
                onClick={() => GotoHome()}
              >
                {Language.translate("GO TO PLACE")}
              </ColorButton>
              <ColorButton
                variant="outlined"
                className={classes.homeButton}
                onClick={() => SetHome()}
              >
                {Language.translate("SAVE PLACE")}
              </ColorButton>
            </Grid>
          </div>
          <div className={classes.panel}>
            <Typography className={classes.text}>
              {Language.translate("Control Panel")}
            </Typography>
            <Grid>
              <ColorButton
                variant="contained"
                className={classes.pipButton}
                onClick={() => {
                  PictureInPicture(true);
                }}
              >
                {Language.translate("IMGE IN IMGE")}
              </ColorButton>
              <ColorButton
                variant="contained"
                className={classes.pipButton}
                onClick={() => {
                  PictureInPicture(false);
                }}
              >
                {Language.translate("IMGE OFF IMGE")}
              </ColorButton>
            </Grid>
            <Grid>
              <ColorButton
                variant="contained"
                className={classes.exportBtn}
                onClick={() => {
                  DownloadLast10();
                }}
              >
                {Language.translate("EXPORT 10s")}
              </ColorButton>
            </Grid>
            <Grid>
              <ColorButton
                variant="contained"
                className={classes.digitalButton}
                onClick={() => {
                  DigitalPTZ(true);
                }}
              >
                {Language.translate("DigitalPTZ ON")}
              </ColorButton>
              <ColorButton
                variant="contained"
                className={classes.digitalButton}
                onClick={() => {
                  DigitalPTZ(false);
                }}
              >
                {Language.translate("DigitalPTZ OFF")}
              </ColorButton>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                className={classes.modeBtn}
                onClick={() => {
                  GotoLive();
                }}
              >
                {Language.translate("Live Mode")}
              </Button>
              <Button
                variant="contained"
                className={classes.modeBtn}
                onClick={() => {
                  GotoReplay();
                }}
              >
                {Language.translate("Replay Mode")}
              </Button>
            </Grid>
            <Grid>
              <ColorButton
                variant="contained"
                className={classes.jpegBtn}
                endIcon={<CropOriginalOutlinedIcon />}
                onClick={() => {
                  QuickJPEG();
                }}
              >
                {Language.translate("Quick JPEG")}
              </ColorButton>
              <ColorButton
                variant="contained"
                className={classes.jpegBtn}
                endIcon={<CropOriginalOutlinedIcon />}
                onClick={() => {
                  HQJPEG();
                }}
              >
                HQ JPEG
              </ColorButton>
            </Grid>
          </div>
          <div className={classes.footer}>
            <Typography>
              {Language.translate("Copyright © 2024 Nortac Defence")}
            </Typography>
          </div>
        </div>
      </Paper>
    </SnackbarProvider>
  );
}
